import React from 'react';
import PropTypes from 'prop-types';
import { CarouselStyled } from '@nike/nike-design-system-components';
import constants from '../../../constants';
import styles from '../relatedFilmstrip.styl';
import carouselCommonStyles from '../../../constants/carouselsCommonStyles.styl';
import SectionHeadline from '../../sectionHeadline';
import { sectionHeadlineShape } from '../../../shapes';

export const RelatedCarousel = ({
  sectionHeadline,
  analyticsItemId,
  headlineAnalyticsId,
  filmstripSlides,
}) => {
  const shouldShowNavigation =
    filmstripSlides.length > constants.MIN_PRODUCT_LIST_LENGTH;
  const navigationMode = shouldShowNavigation
    ? constants.CAROUSEL_NAVIGATION_MODE.buttonAbove
    : constants.CAROUSEL_NAVIGATION_MODE.scroll;

  return (
    <CarouselStyled
      conditionallyHideNavButtons={false}
      navigationMode={navigationMode}
      slidesToShow={{
        xs: constants.MIN_PRODUCT_LIST_LENGTH,
      }}
      alignToGrid
      className={carouselCommonStyles.carousel}
      classes={{
        listContainer: carouselCommonStyles.container,
        mavBtnWrapper: carouselCommonStyles.navigation,
      }}
      slots={{
        TitleSlot: (
          <SectionHeadline
            {...sectionHeadline}
            analyticsItemId={headlineAnalyticsId}
            containerClassName={styles.headline}
            className={styles.headlineText}
          />
        ),
      }}
      titleProps={{
        id: analyticsItemId, // to be used as carousel aria-labelledby attribute value, should repeat id on TitleSlot component, see Carousel component code
      }}
    >
      {filmstripSlides}
    </CarouselStyled>
  );
};

RelatedCarousel.propTypes = {
  sectionHeadline: sectionHeadlineShape,
  analyticsItemId: PropTypes.string,
  headlineAnalyticsId: PropTypes.string,
  filmstripSlides: PropTypes.arrayOf(PropTypes.node),
};
