import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { CAPTION_POSITIONS } from '@nike/ciclp-redux-app';

import ImageMedia from '../imageMedia';
import CardOverlay from '../cardOverlay';
import { CardLink } from '../cardLink';

import { isTextNotEmpty } from '../../helpers/mediaContent';

import { BodyText, SubtitleText, TitleText, CaptionText } from '../textOverlay';
import { MultiCta } from '../ctaButton';

import {
  textLocationShape,
  actionsShape,
  cardStyledTextShape,
  colorThemeShape,
  focalPointShape,
  assetsAspectRatiosShape,
  IMAGE_SIZES,
} from '../../shapes';

import styles from './imageCard.styl';

const ImageCard = ({
  altText,
  withH1,
  bodyProps,
  actionButtons,
  cardLinkId,
  cardLinkUrl,
  destinationType,
  cardLinkCollectionId,
  openInNewTab,
  colorTheme,
  landscapeURL,
  portraitURL,
  subtitleProps,
  textLocation,
  titleProps,
  customClass,
  loadedImageClassNames,
  landscapeFocalPoint,
  portraitFocalPoint,
  analyticsItemId,
  cardId,
  cardLinkMemberOnly,
  captionProps,
  isTextBelowImage,
  assetsAspectRatios,
  imageHeight,
  cardOverlayElement,
}) => {
  const hasActionButtons = actionButtons?.length !== 0;
  const hasTextContent = isTextNotEmpty(
    bodyProps?.text,
    subtitleProps?.text,
    titleProps?.text,
  );

  const cardOverlay = cardOverlayElement || (
    <CardOverlay renderAs="figcaption" position={textLocation}>
      {subtitleProps?.text !== '' && <SubtitleText {...subtitleProps} />}
      {titleProps?.text !== '' && (
        <TitleText
          {...{
            ...titleProps,
            ...(withH1 && { renderAs: 'h1' }),
          }}
        />
      )}
      {bodyProps?.text !== '' && <BodyText {...bodyProps} />}
      {hasActionButtons && (
        <MultiCta
          dataQa={cardId}
          analyticsItemId={analyticsItemId}
          actionButtons={actionButtons}
          colorTheme={colorTheme}
          addTopSpacing={hasTextContent}
        />
      )}
    </CardOverlay>
  );

  return (
    <>
      <figure
        data-analytics-placement-id={analyticsItemId}
        data-analytics-has-landmark="true"
        data-qa="image-card"
        className={cx(['image-card', styles.imageCard])}
      >
        <ImageMedia
          altText={altText}
          landscapeURL={landscapeURL}
          portraitURL={portraitURL}
          customClass={cx([customClass, styles.imageWrapper])}
          loadedImageClassNames={[
            loadedImageClassNames,
            styles.loadedImageWrapper,
          ]}
          landscapeFocalPoint={landscapeFocalPoint}
          portraitFocalPoint={portraitFocalPoint}
          assetsAspectRatios={assetsAspectRatios}
          imageHeight={imageHeight}
          captionProps={captionProps}
          hasBelowLayout={textLocation.vertical === 'after' || isTextBelowImage}
        />
        {captionProps?.text !== '' &&
          captionProps?.position === CAPTION_POSITIONS.BELOW &&
          textLocation?.vertical === 'after' && (
            <CaptionText {...captionProps} />
          )}
        {(hasTextContent || hasActionButtons) && cardOverlay}
        {cardLinkId && (
          <CardLink
            dataQa={cardId}
            memberOnly={cardLinkMemberOnly}
            destinationUrl={cardLinkUrl}
            destinationType={destinationType}
            analyticsItemId={analyticsItemId}
            collectionId={cardLinkCollectionId}
            actionId={cardLinkId}
            openInNewTab={openInNewTab}
            tabbable={!hasActionButtons}
            ariaLabel={`${subtitleProps?.text} ${titleProps?.text}`}
          />
        )}
      </figure>
      {captionProps?.text !== '' &&
        captionProps?.position === CAPTION_POSITIONS.BELOW &&
        textLocation?.vertical !== 'after' && <CaptionText {...captionProps} />}
    </>
  );
};

ImageCard.propTypes = {
  altText: PropTypes.string.isRequired,
  cardLinkMemberOnly: PropTypes.bool,
  withH1: PropTypes.bool,
  bodyProps: cardStyledTextShape,
  actionButtons: actionsShape,
  cardLinkId: PropTypes.string,
  cardLinkUrl: PropTypes.string,
  openInNewTab: PropTypes.bool,
  colorTheme: colorThemeShape,
  landscapeURL: PropTypes.string.isRequired,
  portraitURL: PropTypes.string.isRequired,
  subtitleProps: cardStyledTextShape,
  textLocation: textLocationShape,
  titleProps: cardStyledTextShape,
  customClass: PropTypes.string,
  landscapeFocalPoint: focalPointShape,
  portraitFocalPoint: focalPointShape,
  cardId: PropTypes.string,
  analyticsItemId: PropTypes.string,
  assetsAspectRatios: assetsAspectRatiosShape,
  imageHeight: PropTypes.oneOf(IMAGE_SIZES),
  cardOverlayElement: PropTypes.element,
  loadedImageClassNames: PropTypes.arrayOf(PropTypes.string),
  captionProps: cardStyledTextShape,
  isTextBelowImage: PropTypes.bool,
  destinationType: PropTypes.string,
  cardLinkCollectionId: PropTypes.string,
};

export default ImageCard;
