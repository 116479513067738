import { selectors } from '@nike/ciclp-redux-app';
import { BRAND_NAMES } from '@nike/ciclp-config';

const { translationSelector, brandNameSelector } = selectors;

const nikeOpenGraphData = store => ({
  title: 'Nike. Just Do It. Nike.com',
  description: translationSelector(store, 'socialCard_description'),
  type: 'website',
  imageUrl: `https://c.static-${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/a/images/w_1200,c_limit/bzl2wmsfh7kgdkufrrjq/seo-title.jpg`,
  url: `https://${process.env.NEXT_PUBLIC_HOST_NAME}`,
  locale: 'en_US',
  siteName: 'Nike.com',
});

const getOpenGraphData = store => ({
  [BRAND_NAMES.NIKE]: nikeOpenGraphData(store),
  [BRAND_NAMES.JORDAN]: nikeOpenGraphData(store),
});

const defaultOpenGraphData = store =>
  getOpenGraphData(store)?.[brandNameSelector(store)];

export default defaultOpenGraphData;
