export const getFavicons = () => {
  const baseUrl = `https://${process.env.NEXT_PUBLIC_HOST_NAME}`;

  return [
    {
      rel: 'icon',
      href: `${baseUrl}/favicon.ico?v=1`,
      type: 'image/x-icon',
    },
    {
      rel: 'icon',
      sizes: '192x192',
      href: `${baseUrl}/android-icon-192x192.png`,
      type: 'image/png',
    },
    {
      rel: 'icon',
      sizes: '128x128',
      href: `${baseUrl}/android-icon-128x128.png`,
      type: 'image/png',
    },
    {
      rel: 'apple-touch-icon',
      href: `${baseUrl}/apple-touch-icon.png`,
      type: 'image/png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      href: `${baseUrl}/apple-touch-icon-76x76-precomposed.png`,
      type: 'image/png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: `${baseUrl}/apple-touch-icon-120x120-precomposed.png`,
      type: 'image/png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: `${baseUrl}/apple-touch-icon-152x152-precomposed.png`,
      type: 'image/png',
    },
  ];
};
