import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import cx from 'clsx';
import {
  CARD_ACTION_TYPES,
  CARD_ACTION_DESTINATION_TYPES,
} from '@nike/ciclp-redux-app';

import { actionsShape, colorThemeShape } from '../../shapes';
import StackedCta from '../stackedCta';

import { CtaButton } from './ctaButton';

import styles from './ctaButton.styl';

const ShopTheLook = dynamic(() =>
  import('../../containers/shopTheLook').then(mod => mod.ShopTheLook),
);
const ShopTheLookButton = dynamic(() =>
  import('../../containers/shopTheLook').then(mod => mod.ShopTheLookButton),
);

const NotifyMeButtonContainer = dynamic(() =>
  import('../notifyMeButton').then(mod => mod.NotifyMeButtonContainer),
);

const IframeModal = dynamic(() =>
  import('../iframeModal').then(mod => mod.IframeModal),
);

const actionsForRender = {
  [CARD_ACTION_TYPES.BUTTON]: true,
  [CARD_ACTION_TYPES.LINK]: true,
  [CARD_ACTION_TYPES.BUYING_TOOLS]: true,
};

export const getButtonIndex = (buttons, index) =>
  buttons
    .slice(0, index)
    .reduce(
      (idx, button) =>
        actionsForRender[button.actionType]
          ? idx + 1
          : idx + (button.actions?.length ?? 0),
      0,
    );

export const MultiCta = ({
  actionButtons,
  colorTheme,
  handleClick,
  addTopSpacing,
  className,
  dataQa,
  analyticsItemId,
  ...rest
}) => {
  if (!actionButtons || actionButtons.length === 0) {
    return null;
  }

  return (
    <div
      className={cx(addTopSpacing && styles.addTopSpacing, className)}
      data-qa="cta-container"
    >
      {actionButtons.map((button, idx) => {
        const index = getButtonIndex(actionButtons, idx);

        if (
          button.destinationType ===
          CARD_ACTION_DESTINATION_TYPES.PRODUCT_COLLECTION
        ) {
          return (
            <ShopTheLook
              analyticsItemId={analyticsItemId}
              key={`stl-button-${index}`}
              actionText={button.actionText}
              id={button.id}
            >
              {({ handleOpenModal }) => (
                <ShopTheLookButton
                  handleClick={handleOpenModal}
                  actionText={button.actionText}
                  renderAs="button"
                  type="button"
                  colorTheme={colorTheme}
                  id={button.id}
                  allowAnalytics
                  {...rest}
                >
                  {button.actionText}
                </ShopTheLookButton>
              )}
            </ShopTheLook>
          );
        }

        if (button.actionType === CARD_ACTION_TYPES.STACKED_CTA) {
          return (
            <StackedCta
              key={`cta-button-${index}`}
              dataQa={dataQa}
              index={index}
              actionButton={button}
              colorTheme={colorTheme}
              {...rest}
            />
          );
        }

        if (button.actionType === CARD_ACTION_TYPES.BUYING_TOOLS) {
          return (
            <NotifyMeButtonContainer
              {...button}
              key={`cta-buying-tools-${index}`}
              dataQa={dataQa}
              index={index}
              colorTheme={colorTheme}
              handleClick={button.handleClick || handleClick}
              {...rest}
            />
          );
        }

        if (button.destinationType === CARD_ACTION_DESTINATION_TYPES.POPUP) {
          return (
            <IframeModal
              src={button.destinationId}
              key={`iframe-modal-${index}`}
              title={button.actionText}
            >
              {({ onClick }) => (
                <CtaButton
                  handleClick={onClick}
                  actionText={button.actionText}
                  renderAs="button"
                  type="button"
                  colorTheme={colorTheme}
                  id={button.id}
                  allowAnalytics
                  {...rest}
                >
                  {button.actionText}
                </CtaButton>
              )}
            </IframeModal>
          );
        }

        return (
          <CtaButton
            {...button}
            key={`cta-${
              button.actionType === CARD_ACTION_TYPES.BUTTON
                ? 'button'
                : 'text-link'
            }-${index}`}
            dataQa={dataQa}
            colorTheme={colorTheme}
            handleClick={button.handleClick || handleClick}
            {...rest}
          />
        );
      })}
    </div>
  );
};

MultiCta.propTypes = {
  actionButtons: actionsShape,
  colorTheme: colorThemeShape,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  dataQa: PropTypes.string,
  addTopSpacing: PropTypes.bool,
  analyticsItemId: PropTypes.string,
};

MultiCta.defaultProps = {
  colorTheme: 'dark',
};
