import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectors } from '@nike/ciclp-redux-app';
import { MEASUREMENT_TYPE } from '@nike/ciclp-config';

const BraSizeFinder = dynamic(() =>
  import('../../components/braSizeFinder').then(mod => mod.BraSizeFinder),
);

export const BraSizeFinderContainer = ({
  slides,
  urls,
  sectionHeadline,
  translations = {},
  analyticsItemId,
  countryCode,
}) => {
  const savedMeasurementsSettings = useSelector(
    selectors.measurementSelector,
    shallowEqual,
  );

  return (
    <BraSizeFinder
      slides={slides}
      urls={urls}
      title={sectionHeadline?.title}
      subtitle={sectionHeadline?.subtitle}
      translations={translations}
      selectedUnits={savedMeasurementsSettings?.[MEASUREMENT_TYPE]}
      analyticsItemId={analyticsItemId}
      countryCode={countryCode}
    />
  );
};

BraSizeFinderContainer.propTypes = {
  urls: PropTypes.objectOf(PropTypes.string),
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      titleProps: PropTypes.shape({
        text: PropTypes.string,
      }),
      subtitleProps: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
  ),
  sectionHeadline: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  translations: PropTypes.shape({
    next: PropTypes.string,
    bra_size_finder_alpha_caption: PropTypes.string,
    bra_size_finder_hybrid_caption: PropTypes.string,
    bra_size_finder_restart: PropTypes.string,
    bra_size_finder_shop_link: PropTypes.string,
    bra_size_finder_underbust_input_caption: PropTypes.string,
    units_inch: PropTypes.string,
    units_cm: PropTypes.string,
  }),
  analyticsItemId: PropTypes.string,
  countryCode: PropTypes.string,
};
