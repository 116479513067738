import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './titleCard.styl';

export const TitleMeta = ({
  authors,
  lastUpdated,
  lastUpdatedTemplate,
  lastUpdatedStamp,
  readTime,
  readTimeTemplate,
}) => {
  const shouldShowFirstMetaLine = !!lastUpdated || !!readTime;
  const shouldShowSecondMetaLine = !!(authors && authors.roleTitle);
  const lastUpdatedFormatted =
    !!lastUpdated && lastUpdatedTemplate?.replace('[date]', lastUpdated);
  const readTimeFormatted =
    !!readTime && readTimeTemplate?.replace('[durationInMinutes]', readTime);

  return (
    <>
      {shouldShowFirstMetaLine && (
        <div className={styles.metadataWrapper}>
          <div
            className={cx(
              'body-2',
              'text-color-secondary',
              'mt6-sm',
              styles.metadataLine,
            )}
          >
            {!!lastUpdatedFormatted && (
              <div className={styles.metadata} data-qa="lastUpdated">
                {lastUpdatedFormatted}
                <meta itemProp="dateModified" content={lastUpdatedStamp} />
              </div>
            )}
            {readTimeFormatted && (
              <div className={styles.metadata} data-qa="readTime">
                {readTimeFormatted}
              </div>
            )}
          </div>
        </div>
      )}
      {shouldShowSecondMetaLine && (
        <div
          data-qa="authors"
          className={cx(
            'body-2',
            'text-color-secondary',
            'mt6-sm',
            styles.byline,
          )}
        >
          {`${authors.roleTitle}: ${authors.profiles
            .map(profile => profile.fullName)
            .join(', ')}`}
        </div>
      )}
    </>
  );
};

TitleMeta.propTypes = {
  authors: PropTypes.shape({
    roleTitle: PropTypes.string,
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
      }),
    ),
  }),
  lastUpdated: PropTypes.string,
  lastUpdatedTemplate: PropTypes.string,
  lastUpdatedStamp: PropTypes.string,
  readTime: PropTypes.number,
  readTimeTemplate: PropTypes.string,
};
