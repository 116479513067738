import React from 'react';
import PropTypes from 'prop-types';

const NavigationLink = ({
  href,
  label,
  target,
  onClick,
  children,
  ...props
}) => (
  <a
    href={href}
    target={target}
    {...(target === '_blank' && { rel: 'noopener' })}
    {...props}
  >
    {children || label}
  </a>
);

NavigationLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default NavigationLink;
