import React from 'react';
import cx from 'clsx';
import { BRAND_NAMES } from '@nike/ciclp-config';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import { smartTextCut } from '@nike/ciclp-redux-app/services/transforms/helpers';
import ImageMedia from '../imageMedia';
import { CardLink } from '../cardLink';
import styles from './relatedFilmstripSlide.styl';
import { gallerySlideShape } from '../../shapes';
import { BrandContext, DimensionTypeContext } from '../../context';
import { setCustomTextColor } from '../../helpers/setCustomTextColor';
import { VideoMedia } from '../videoMedia';

const createMarkup = body => ({
  __html: body,
});

const MAX_BODY_LENGTH_MOBILE = 100;
const MAX_BODY_LENGTH = 160;

const getComponent = slide =>
  ({
    [CARD_TYPES.IMAGE]: (
      <ImageMedia
        {...slide}
        loadedImageClassNames={[styles.imageIsLoaded]}
        customClass={cx([styles.imageMedia, styles.imageWrapper])}
      />
    ),
    [CARD_TYPES.VIDEO]: (
      <VideoMedia
        {...slide}
        loop={false}
        autoPlay={false}
        showPlayButton
        alwaysMobile
        watchButtonText={slide.watchCtaButtonText}
        customClass={styles.imageMedia}
        timestampWithPlay
      />
    ),
  }[slide.containerType] ?? null);

export const RelatedFilmstripSlide = ({
  analyticsItemId,
  cardLinkMemberOnly,
  ...slide
}) => {
  return (
    <figure
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      data-qa="related-item-card"
      className={cx([styles.slide, styles.filmstripCard])}
    >
      {getComponent(slide)}
      <CardLink
        actionId={slide.cardLinkId}
        memberOnly={cardLinkMemberOnly}
        destinationUrl={slide.cardLinkUrl}
        openInNewTab={slide.openInNewTab}
        renderAs="a"
        classNames={[styles.cardLink]}
        ariaLabel={[slide.subtitleProps.text, slide.titleProps.text].join(' ')}
      />
      <figcaption className={styles.slideTextWrapper}>
        {slide.subtitleProps.text && (
          <h4
            data-qa="title"
            className={cx([
              'body-2',
              'text-color-secondary',
              'responsive-body-3-2',
              styles.slideTitle,
            ])}
            style={setCustomTextColor(slide.subtitleProps)}
            dangerouslySetInnerHTML={createMarkup(slide.subtitleProps.text)}
          />
        )}

        <BrandContext.Consumer>
          {brand => (
            <DimensionTypeContext.Consumer>
              {deviceInfo => (
                <p
                  className={cx([
                    brand === BRAND_NAMES.JORDAN ? 'headline-5' : 'headline-4',
                    'responsive-body-2-1',
                    styles.slideBodyText,
                  ])}
                  style={setCustomTextColor(slide.titleProps)}
                  dangerouslySetInnerHTML={createMarkup(
                    smartTextCut(
                      slide.titleProps.text,
                      deviceInfo.isDesktop
                        ? MAX_BODY_LENGTH
                        : MAX_BODY_LENGTH_MOBILE,
                    ),
                  )}
                />
              )}
            </DimensionTypeContext.Consumer>
          )}
        </BrandContext.Consumer>
      </figcaption>
    </figure>
  );
};

RelatedFilmstripSlide.propTypes = gallerySlideShape;
