import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectors } from '@nike/ciclp-redux-app';
import { ProductContainer } from '../../components/productContainer';
import withAnimation from '../../components/withAnimation';
import constants from '../../constants';
import { RecommendationsCarousel } from '../../components/recommendationsCarousel';
import { productContainerShape } from '../../shapes';

const AnimatedContainer = withAnimation(ProductContainer);

export const RecommenderFallback = ({
  fallbacks,
  useFallback,
  analyticsItemId,
  canRunAnimation,
  taxonomies,
  topTrendingTitle,
  clearance,
  ...props
}) => {
  const fallbackCards = useSelector(
    state => selectors.getFallbackDataSelector(state, fallbacks),
    shallowEqual,
  );

  const fallbackCardWithEnoughSlides = fallbackCards?.find(
    ({ slides = [] } = {}) =>
      slides.length >= constants.MIN_PRODUCT_LIST_LENGTH,
  );

  if (useFallback && !fallbackCardWithEnoughSlides) {
    const title = props?.sectionHeadline?.title ?? topTrendingTitle;
    return (
      <RecommendationsCarousel
        carouselId="ciclp.top-trending"
        title={title}
        clearance={clearance}
        maxResults={props.maxResults}
        taxonomies={taxonomies}
      />
    );
  }

  const recommenderProps = useFallback ? fallbackCardWithEnoughSlides : props;
  return (
    <AnimatedContainer
      animateOnce
      isActive={canRunAnimation}
      observePropertyPath={['slides']}
      animationDelay={500}
      analyticsItemId={analyticsItemId}
      {...recommenderProps}
    />
  );
};

RecommenderFallback.defaultProps = {
  useFallback: false,
  clearance: false,
  fallbacks: [],
};

RecommenderFallback.propTypes = {
  ...productContainerShape,
  clearance: PropTypes.bool,
  useFallback: PropTypes.bool,
  fallbacks: PropTypes.array,
  analyticsItemId: PropTypes.string,
  canRunAnimation: PropTypes.bool,
  taxonomies: PropTypes.arrayOf(PropTypes.string),
  topTrendingTitle: PropTypes.string,
};
