import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import logger from '@nike/ciclp-utils/logger';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, info) {
    logger.warn({
      name: '[CLP]: Component is broken during rendering',
      message: error.message,
      details: {
        errorStack: error.stack,
        componentStack: info.componentStack,
      },
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) return null;

    return <>{children}</>;
  }
}

ErrorBoundary.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
