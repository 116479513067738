import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { PullQuote } from '@nike/nike-design-system-components';
import { BodyText } from '../textOverlay';
import { isTextNotEmpty } from '../../helpers/mediaContent';
import styles from './pullQuoteCard.styl';
import { MultiCta } from '../ctaButton';
import { CardLink } from '../cardLink';
import {
  cardStyledTextShape,
  genericCardDataShape,
  actionButtonsShape,
  textLocationShape,
  colorThemeShape,
} from '../../shapes';
import { isTextPresent } from '../../helpers/text';

const setPlacementStyles = ({ horizontal }) => {
  const horizontalFix = horizontal === 'before' ? 'start' : horizontal;
  return styles[`h_${horizontalFix}`];
};

export const PullQuoteCard = ({
  bodyProps,
  analyticsItemId,
  titleProps,
  subtitleProps,
  backgroundColor,
  textLocation,
  cardLinkId,
  cardLinkUrl,
  openInNewTab,
  actionButtons,
  cardLinkMemberOnly,
  colorTheme,
  destinationType,
  cardLinkCollectionId,
}) => {
  const isBodyEmpty = !isTextPresent(bodyProps?.text);
  const title = titleProps?.text;

  if (!title && isBodyEmpty) {
    return null;
  }

  return (
    <div
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      className={cx([
        styles.pullQuoteCard,
        !isBodyEmpty && styles.withBody,
        setPlacementStyles(textLocation ?? {}),
      ])}
      data-qa="pull-quote-card"
      style={{ backgroundColor }}
    >
      {cardLinkId && (
        <CardLink
          actionId={cardLinkId}
          destinationType={destinationType}
          analyticsItemId={analyticsItemId}
          collectionId={cardLinkCollectionId}
          memberOnly={cardLinkMemberOnly}
          destinationUrl={cardLinkUrl}
          openInNewTab={openInNewTab}
          tabbable={!actionButtons?.length}
          ariaLabel={`${subtitleProps?.text} ${titleProps?.text}`}
        />
      )}
      <PullQuote
        headerText={title}
        slots={{
          TextSlot: (
            <BodyText
              {...bodyProps}
              customClassName={styles.textBody}
              renderAs="div"
            />
          ),
        }}
      />
      {actionButtons?.length ? (
        <MultiCta
          actionButtons={actionButtons}
          colorTheme={colorTheme}
          analyticsItemId={analyticsItemId}
          addTopSpacing={isTextNotEmpty(
            bodyProps.text,
            subtitleProps.text,
            titleProps.text,
          )}
        />
      ) : null}
    </div>
  );
};

PullQuoteCard.propTypes = {
  bodyProps: cardStyledTextShape,
  titleProps: cardStyledTextShape,
  subtitleProps: cardStyledTextShape,
  backgroundColor: PropTypes.string,
  cardLinkMemberOnly: PropTypes.bool,
  textLocation: textLocationShape,
  cardLinkId: PropTypes.string,
  cardLinkUrl: PropTypes.string,
  actionButtons: actionButtonsShape,
  colorTheme: colorThemeShape,
  properties: genericCardDataShape,
  analyticsItemId: PropTypes.string,
  openInNewTab: PropTypes.bool,
  destinationType: PropTypes.string,
  cardLinkCollectionId: PropTypes.string,
  templateType: PropTypes.string,
};
