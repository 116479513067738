import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import NavigationLink from '../navigationLink';
import { TitleText } from '../textOverlay';

import styles from './sectionHeadline.styl';

// As per Leo's request, override action type to be equal to 'card_link'
const renderLink = (action, label) => (
  <NavigationLink
    data-analytics-action-id={action.id}
    className={cx('btn-lg', styles.cta)}
    href={action.destinationId}
    aria-label={label?.replace('[link]', action.destinationId)}
    target={action?.openInNewTab ? '_blank' : '_self'}
  >
    {action.actionText}
  </NavigationLink>
);

const getAction = actions => {
  if (!actions || actions.length === 0 || !actions[0].destinationId) {
    return null;
  }

  return actions[0];
};

// As per Leo's request, not using cta button even if defined
export const SectionHeadline = ({
  analyticsItemId,
  actions,
  title,
  subtitle,
  withH1,
  className,
  containerClassName,
  sectionLabel,
  textLocation,
  titleProps,
  ToggleComponent,
}) => {
  if (!title) {
    return null;
  }
  const {
    fontFamily = 'base',
    fontSize,
    fontStyle,
    colorTheme,
    textColor,
  } = titleProps;
  const action = getAction(actions);
  const isToggleComponentExists = !!ToggleComponent;
  const alignmentStyle = styles[`title-align-${textLocation.horizontal}`];

  return (
    <>
      {subtitle ? <p className="headline-5 mb1-sm">{subtitle}</p> : null}
      <div
        data-analytics-placement-id={analyticsItemId}
        data-qa="section-headline"
        className={cx(styles.headline, containerClassName)}
      >
        <div
          className={cx(styles.sectionHeadlineContent, {
            [styles.sectionHeadlineContentWithActions]:
              isToggleComponentExists || action,
          })}
        >
          <TitleText
            text={title}
            textLocation={textLocation}
            customClassName={cx(
              {
                [styles.sectionHeadlineTitle]: isToggleComponentExists,
              },
              alignmentStyle,
              styles.copy,
              className,
            )}
            fontFamily={fontFamily}
            fontSize={fontSize}
            colorTheme={colorTheme}
            textColor={textColor}
            fontStyle={fontStyle}
            renderAs={withH1 ? 'h1' : 'h2'}
          />
          {action && renderLink(action, sectionLabel)}
        </div>
        {isToggleComponentExists && (
          <div className={styles.toggleWrapper}>{ToggleComponent}</div>
        )}
      </div>
    </>
  );
};

SectionHeadline.propTypes = {
  withH1: PropTypes.bool,
  analyticsItemId: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      actionText: PropTypes.string.isRequired,
      destinationType: PropTypes.string,
      destinationId: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  sectionLabel: PropTypes.string,
  textLocation: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  titleProps: PropTypes.shape({
    fontFamily: PropTypes.string,
    fontSize: PropTypes.string,
    fontStyle: PropTypes.string,
    colorTheme: PropTypes.string,
    textColor: PropTypes.string,
  }),
  ToggleComponent: PropTypes.element,
};

SectionHeadline.defaultProps = {
  actions: [],
  titleProps: {},
  textLocation: {},
};

export default SectionHeadline;
