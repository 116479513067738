/**
 * Focus-visible polyfill is required by NCSS v4 only in Safari.
 * Required for a11y in latest nessie. Could be removed after adopting of Button component from Nike Design System Components,
 * https://tourguide.prod.commerce.nikecloud.com/ncss#ncss-new-in-ncss-4
 */

import { isWeb } from '@nike/ciclp-config';

export const isSafari = () => {
  if (!isWeb()) {
    return false;
  }
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false;
    }
    return true;
  }
  return false;
};

export const loadPolyfills = () => {
  if (isSafari()) {
    import('focus-visible');
  }
};
