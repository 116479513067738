import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import cx from 'clsx';
import { CarouselStyled } from '@nike/nike-design-system-components';
import { FilmstripSlide } from './filmstripSlide';
import styles from './filmstrip.styl';
import carouselCommonStyles from '../../constants/carouselsCommonStyles.styl';
import SectionHeadline from '../sectionHeadline';
import { sectionHeadlineShape, gallerySlideShape } from '../../shapes';
import constants from '../../constants';

export const Filmstrip = ({ analyticsItemId, sectionHeadline, slides }) => {
  const filmstripItems = take(constants.MAX_PRODUCT_LIST_LENGTH, slides);
  const hasLessSlidesThanScreenContains =
    filmstripItems.length < constants.MIN_PRODUCT_LIST_LENGTH;
  const shouldShowArrows =
    filmstripItems.length > constants.MIN_PRODUCT_LIST_LENGTH;
  const navigationMode = shouldShowArrows
    ? constants.CAROUSEL_NAVIGATION_MODE.buttonAbove
    : constants.CAROUSEL_NAVIGATION_MODE.scroll;

  if (hasLessSlidesThanScreenContains) {
    return null;
  }

  return (
    <div data-qa="carousel-filmstrip" className={cx(styles.filmstrip)}>
      <CarouselStyled
        conditionallyHideNavButtons={false}
        navigationMode={navigationMode}
        slidesToShow={{
          xs: constants.MIN_PRODUCT_LIST_LENGTH,
        }}
        alignToGrid
        className={carouselCommonStyles.carousel}
        classes={{
          listContainer: carouselCommonStyles.container,
          navBtnWrapper: carouselCommonStyles.navigation,
        }}
        slots={{
          TitleSlot: (
            <SectionHeadline
              {...sectionHeadline}
              analyticsItemId={`${analyticsItemId}_0`}
              containerClassName={styles.headline}
            />
          ),
        }}
        titleProps={{
          id: analyticsItemId, // to be used as carousel aria-labelledby attribute value, should repeat id on TitleSlot component, see Carousel component code
        }}
      >
        {filmstripItems.map((slide, index) => {
          const analyticsSlideId = `${analyticsItemId}_${index + 1}`;
          return (
            <FilmstripSlide
              key={analyticsSlideId}
              {...slide}
              sectionHeadlineTitle={sectionHeadline.title}
              analyticsItemId={analyticsSlideId}
            />
          );
        })}
      </CarouselStyled>
    </div>
  );
};

Filmstrip.propTypes = {
  analyticsItemId: PropTypes.string,
  sectionHeadline: sectionHeadlineShape,
  slides: PropTypes.arrayOf(PropTypes.shape(gallerySlideShape)),
};
