import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { BackdropStyled } from '@nike/nike-design-system-components';
import { TitleComponentBase, LinkComponentBase } from './LocalNavItem';
import { useWindowEvent } from './hooks';

const keyMap = {
  esc: 27,
  enter: 13,
  upArrow: 38,
  downArrow: 40,
  space: 32,
};

export const LocalNavDropdown = props => {
  const navRef = useRef(null);
  const hasItems = !!props?.items?.length;
  const ref = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const [wasOpenedWhenStatic, setWasOpenedWhenStatic] = useState(false);
  const {
    titleProps,
    items,
    TitleComponent,
    LinkComponent,
    className,
    DropdownItem,
    prefix,
    isSticky,
    customClassName,
    placeHolderClass,
  } = props;

  const setOpenStatus = status => {
    setIsOpened(status);
    const bodyOverFlow =
      status && isSticky && navRef.current?.getBoundingClientRect().bottom < 250
        ? 'hidden'
        : 'auto';

    if (status) {
      setWasOpenedWhenStatic(!isSticky);
    }
    document.body.style.overflow = bodyOverFlow;
  };

  useWindowEvent('scroll', () => {
    if (
      isSticky &&
      isOpened &&
      navRef.current?.getBoundingClientRect().bottom < 250
    ) {
      setOpenStatus(false);
    }
  });

  const handleOpenStateByKey = ev => {
    if (!isOpened && [keyMap.enter, keyMap.space].includes(ev?.keyCode)) {
      setOpenStatus(true);
      ref.current.querySelector('li:first-child a')?.focus();
    } else if (isOpened && keyMap.esc === ev?.keyCode) {
      setOpenStatus(false);
      ref.current.querySelector('.local-nav-dropdown-title')?.focus();
    }
  };
  const handleMenuKeyNavigation = ev => {
    switch (ev.keyCode) {
      case keyMap.upArrow:
        // eslint-disable-next-line no-case-declarations
        const previousItem = ev.target.parentNode.previousElementSibling;
        if (previousItem) {
          previousItem.firstElementChild.focus();
        } else {
          ref.current.querySelector('li:last-of-type a')?.focus();
        }
        break;
      case keyMap.downArrow:
      case keyMap.tab:
        // eslint-disable-next-line no-case-declarations
        const nextItem = ev.target.parentNode.nextElementSibling;
        if (nextItem) {
          nextItem.firstElementChild?.focus();
        } else {
          ref.current.querySelector('li:first-of-type a')?.focus();
        }
        break;
      default:
        break;
    }
  };

  if (!titleProps?.text && !items?.length) {
    return null;
  }

  return (
    <div
      className={clsx([
        className,
        customClassName,
        wasOpenedWhenStatic && 'local-nav-opened-static',
        isOpened && 'local-nav-dropdown-opened',
        !isOpened && 'local-nav-dropdown-hidden',
      ])}
      ref={ref}
    >
      <div
        id={`${prefix}menu-title`}
        className={clsx([
          'local-nav-dropdown-title',
          !wasOpenedWhenStatic && isSticky && placeHolderClass,
        ])}
        {...(hasItems && {
          role: 'button',
          tabIndex: 0,
          'aria-expanded': isOpened,
          'aria-haspopup': true,
          'aria-controls': `${prefix}menu-list`,
          onClick: () => setOpenStatus(!isOpened),
          onKeyDown: handleOpenStateByKey,
        })}
      >
        <TitleComponent
          {...titleProps}
          className={clsx(['local-nav-title', titleProps?.className])}
        />
        {hasItems && <div className="local-nav-dropdown-icon" />}
      </div>
      {hasItems && (
        <nav className="local-nav-dropdown-nav">
          <BackdropStyled
            closeModal={() => setOpenStatus(false)}
            shouldCloseOnBackgroundClick
            className="local-nav-dropdown-backdrop"
          />
          <ul
            ref={navRef}
            id={`${prefix}menu-list`}
            role="menu"
            aria-labelledby={`${prefix}menu-title`}
            className="local-nav-dropdown-list"
            tabIndex={-1}
            onKeyDown={handleOpenStateByKey}
          >
            {items.map((item, index) => (
              <DropdownItem
                role="none"
                className="local-nav-dropdown-item"
                key={index}
                index={index}
                length={items.length}
              >
                <LinkComponent
                  role="menuitem"
                  appearance="heading4"
                  tabIndex={isOpened ? 0 : -1}
                  {...item}
                  onKeyDown={handleMenuKeyNavigation}
                />
              </DropdownItem>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

LocalNavDropdown.propTypes = {
  /** Component to render title in local nav */
  TitleComponent: PropTypes.elementType,
  /** Component to render dropdown item in local nav */
  DropdownItem: PropTypes.elementType,
  /** Component to render links in menu  */
  LinkComponent: PropTypes.elementType,
  /** Class name(s) to apply to the root element. */
  className: PropTypes.string,
  /** Props that are passed to title component */
  titleProps: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  }),
  /** Props that are passed to title component */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string.isRequired,
      className: PropTypes.string,
    }),
  ),
  /** prefix for ids inside the component */
  prefix: PropTypes.string,
  /** Indicator of Local Nav state */
  isSticky: PropTypes.bool,
  /** Custom class name applied to container */
  customClassName: PropTypes.string,
  /** Class name applied when navigation is sticky to avoid content jumping */
  placeHolderClass: PropTypes.string,
};

LocalNavDropdown.defaultProps = {
  TitleComponent: TitleComponentBase,
  LinkComponent: LinkComponentBase,
  DropdownItem: 'li',
  prefix: '',
};
