import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { CAPTION_POSITIONS, IMAGE_SIZES_MAP } from '@nike/ciclp-redux-app';
import {
  imageShape,
  focalPointShape,
  assetsAspectRatiosShape,
  IMAGE_SIZES,
  captionShape,
} from '../../shapes';

import { useDimensionTypeContext } from '../../context';
import ImageWrapper from './imageWrapper';
import { CloudinaryImage } from '../cloudinaryImage';
import AnimatedVisibilitySensor from '../animatedVisibilitySensor';
import { ImageElement } from './imageElement';
import styles from './imageMedia.styl';
import { VideoElement } from './videoElement';
import { CaptionText } from '../textOverlay';

export const ImageMedia = props => {
  const [isImageLoaded, setImageLoad] = useState(false);
  const { isPortrait } = useDimensionTypeContext();
  const {
    altText,
    portraitURL,
    landscapeURL,
    customClass,
    landscapeFocalPoint,
    portraitFocalPoint,
    draggable,
    assetsAspectRatios,
    loadedImageClassNames,
    imageHeight,
    useVisibilitySensor = true,
    onImageLoad,
    captionProps = {},
    hasBelowLayout,
  } = props;
  const handleImageLoaded = useCallback(() => {
    setImageLoad(true);
    onImageLoad?.();
  }, [onImageLoad]);

  useEffect(() => {
    if (portraitURL || landscapeURL) {
      setImageLoad(false);
    }
  }, [portraitURL, landscapeURL]);

  const focalPoint =
    isPortrait || !landscapeURL ? portraitFocalPoint : landscapeFocalPoint;

  const getUrl = (isVisible, url) => (isVisible ? url : '');

  return (
    <ImageWrapper
      height={imageHeight}
      aspectRatios={assetsAspectRatios}
      isLoaded={isImageLoaded}
      useVisibilitySensor={useVisibilitySensor}
      data-qa="image-wrapper"
      className={cx(
        customClass,
        styles.imageMedia,
        'media-container',
        isImageLoaded && loadedImageClassNames,
        imageHeight === IMAGE_SIZES_MAP.MAINTAIN &&
          !hasBelowLayout &&
          styles.overlayedMaintainImage,
      )}
    >
      <CloudinaryImage
        dataAttrs={{
          'data-qa': 'image-media',
        }}
        portraitURL={portraitURL}
        landscapeURL={landscapeURL}
      >
        {url =>
          useVisibilitySensor ? (
            <AnimatedVisibilitySensor onLoad={handleImageLoaded}>
              {({ isVisible = true, onLoad }) =>
                url?.includes('.gif') ? (
                  <VideoElement
                    isImageLoaded={isImageLoaded}
                    focalPoint={focalPoint}
                    url={getUrl(isVisible, url)}
                    onLoad={onLoad}
                  />
                ) : (
                  <ImageElement
                    draggable={draggable}
                    altText={altText}
                    isImageLoaded={isImageLoaded}
                    focalPoint={focalPoint}
                    url={getUrl(isVisible, url)}
                    onLoad={onLoad}
                    portraitURL={portraitURL}
                    landscapeURL={landscapeURL}
                  />
                )
              }
            </AnimatedVisibilitySensor>
          ) : (
            <ImageElement
              url={url}
              className={styles.imageWithoutSensor}
              draggable={draggable}
              altText={altText}
              focalPoint={focalPoint}
              onLoad={handleImageLoaded}
              isImageLoaded={isImageLoaded}
              portraitURL={portraitURL}
              landscapeURL={landscapeURL}
            />
          )
        }
      </CloudinaryImage>
      {captionProps.text !== '' &&
        captionProps.position === CAPTION_POSITIONS.OVERLAY && (
          <CaptionText {...captionProps} />
        )}
    </ImageWrapper>
  );
};

ImageMedia.displayName = 'ImageMedia';

ImageMedia.propTypes = {
  altText: PropTypes.string.isRequired,
  landscapeURL: PropTypes.string.isRequired,
  portraitURL: PropTypes.string.isRequired,
  // landscape and portrait are accessed dynamically from getImageOverride
  landscape: imageShape, // eslint-disable-line react/no-unused-prop-types
  portrait: imageShape, // eslint-disable-line react/no-unused-prop-types
  customClass: PropTypes.string,
  useVisibilitySensor: PropTypes.bool,
  draggable: PropTypes.bool,
  onImageLoad: PropTypes.func,
  landscapeFocalPoint: focalPointShape,
  portraitFocalPoint: focalPointShape,
  assetsAspectRatios: assetsAspectRatiosShape,
  loadedImageClassNames: PropTypes.arrayOf(PropTypes.string),
  imageHeight: PropTypes.oneOf(IMAGE_SIZES),
  captionProps: captionShape,
  hasBelowLayout: PropTypes.bool,
};

ImageMedia.defaultProps = {
  landscape: null,
  portrait: null,
  assetsAspectRatios: {},
  loadedImageClassNames: [],
};

export default ImageMedia;
