import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RemoteComponent } from '@nike/remote-component';
import { selectors, analyticsViewProperties } from '@nike/ciclp-redux-app';

export const InterestsContainer = ({ title, subtitle, maxResults }) => {
  const pageType = useSelector(selectors.analyticsPageTypeSelector);
  const pageName = useSelector(selectors.pageNameSelector);
  const pageDetail = useSelector(selectors.pageDetailSelector);

  const analyticsConfig = {
    experienceType: analyticsViewProperties.SITE,
    pageDetail,
    pageName,
    pageType,
  };
  return (
    <RemoteComponent
      analyticsConfig={analyticsConfig}
      endpoint={`https://${process.env.NEXT_PUBLIC_HOST_NAME}/fragments/dotcom-interests`}
      title={title}
      subtitle={subtitle}
      maxResults={maxResults}
      type="carousel"
    />
  );
};

InterestsContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  maxResults: PropTypes.number,
};
