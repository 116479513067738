export const VIDEO_ANALYTICS_EVENT = 'videoAnalyticsLpEvent';
export const VIDEO_DURATION_TOLERANCE = 2;
export const VIDEO_EVENTS = {
  PLAY: 'play',
  PAUSE: 'pause',
  ENDED: 'ended',
  SEEKING: 'seeking',
  VOLUMECHANGE: 'volumechange',
  FULLSCREENCHANGE: 'fullscreenchange',
  TEXT_TRACK_CHANGE: 'texttrackschange',
  LOADEDDATA: 'loadeddata',
  LOADEDMETADATA: 'loadedmetadata',
  LOADSTART: 'loadstart',
  PREVIEW_STARTED: 'previewstarted',
  PREVIEW_STOPPED: 'previewstopped',
  CANPLAY: 'canplay',
  STATECHANGED: 'statechanged',
  PLAYERRESIZE: 'playerresize',
  DISPOSE: 'dispose',
};

export const VIDEO_JS_UTILITY_CLASSES = {
  POSTER_INACTIVE: 'vjs-poster-inactive',
  INITIATED_BY_USER: 'vjs-initiated-by-user',
  HIDDEN: 'vjs-hidden',
};
