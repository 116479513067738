import React, { useContext } from 'react';
import cx from 'clsx';
import { always, assocPath, lensProp, map, over, pipe } from 'ramda';
import { BRAND_NAMES } from '@nike/ciclp-config';

import styles from './textOverlay.styl';

import { cardStyledTextProperties } from '../../shapes';
import { BrandContext } from '../../context';
import { setCustomTextColor } from '../../helpers/setCustomTextColor';

const subTitleSizeMapping = {
  extra_small: 'headline-5',
  small: 'headline-5',
  medium: 'headline-5',
  large: 'headline-5',
  extra_large: 'headline-5',
};

const bodySizeMapping = {
  extra_small: 'body-2 mt6-sm',
  small: 'responsive-body-2-1 mt6-sm',
  medium: 'body-1 mt6-sm',
  large: 'body-1 mt6-sm',
  extra_large: 'body-1 mt6-sm',
};

const captionSizeMapping = {
  extra_small: 'body-3',
  small: 'body-3',
  medium: 'body-3',
  large: 'body-3',
  extra_large: 'body-3',
};

const textMapper = {
  heading: {
    marketing: {
      extra_small: 'display-2',
      small: 'display-2',
      medium: 'responsive-display-2-1',
      large: 'responsive-display-2-1',
      extra_large: 'responsive-display-2-1',
    },
    brand: {
      extra_small: 'display-2',
      small: 'display-2',
      medium: 'responsive-display-2-1',
      large: 'responsive-display-2-1',
      extra_large: 'responsive-display-2-1',
    },
    base: {
      extra_small: 'headline-5',
      small: 'headline-4',
      medium: 'headline-3',
      large: 'headline-2',
      extra_large: 'headline-1',
    },
  },
  subTitle: {
    marketing: subTitleSizeMapping,
    brand: subTitleSizeMapping,
    base: subTitleSizeMapping,
  },
  caption: {
    marketing: captionSizeMapping,
    brand: captionSizeMapping,
    base: captionSizeMapping,
  },
  body: {
    marketing: bodySizeMapping,
    brand: bodySizeMapping,
    base: bodySizeMapping,
  },
};

const jordanMarketingHeading = {
  extra_small: 'display-3',
  small: 'display-3',
  medium: 'display-2',
  large: 'responsive-display-2-1',
  extra_large: 'responsive-display-2-1',
};

const nikeJournalMarketingHeading = {
  extra_small: 'u-text-transform-unset marketing-font responsive-display-4-2',
  small: 'u-text-transform-unset marketing-font responsive-display-4-2',
  medium:
    'u-text-transform-unset u-futura-line-height u-futura-font-size marketing-font responsive-display-2-1',
  large:
    'u-text-transform-unset u-futura-line-height u-futura-font-size marketing-font responsive-display-2-1',
  extra_large:
    'u-text-transform-unset u-futura-line-height u-futura-font-size marketing-font',
};

const jordanBody = {
  extra_small: 'body-3',
  small: 'body-2',
  medium: 'body-1',
  large: 'body-1',
  extra_large: 'body-1',
};

const nikeJournalMarketingBody = {
  extra_small: 'body-2 mt3-sm',
  small: 'responsive-body-2-1 mt3-sm',
  medium: 'body-1 mt3-sm',
  large: 'body-1 mt3-sm',
  extra_large: 'body-1 mt3-sm',
};

const jordanTextMapper = pipe(
  assocPath(['heading', 'marketing'], jordanMarketingHeading),
  assocPath(['heading', 'brand'], jordanMarketingHeading),
  over(lensProp('subTitle'), map(map(always('body-3')))),
  over(lensProp('body'), map(always(jordanBody))),
)(textMapper);

const nikeJournalMapper = pipe(
  assocPath(['heading', 'marketing'], nikeJournalMarketingHeading),
  assocPath(['heading', 'brand'], nikeJournalMarketingHeading),
  over(lensProp('body'), map(always(nikeJournalMarketingBody))),
)(textMapper);

const fontStyleMapper = {
  medium: styles.fontStyle_medium,
  oblique: styles.fontStyle_oblique,
};

const brandTextMapper = {
  [BRAND_NAMES.JORDAN]: jordanTextMapper,
  [BRAND_NAMES.NIKE_JOURNAL]: nikeJournalMapper,
};

const getFontStyles = (
  fontFamily,
  fontStyle,
  fontSize,
  textType,
  brand = BRAND_NAMES.NIKE,
) => [
  (brandTextMapper[brand] ?? textMapper)[textType][fontFamily][fontSize],
  fontStyleMapper[fontStyle],
];

function getFontColor(colorTheme) {
  return `text-color-primary-${colorTheme}`;
}

export const css = (
  fontFamily,
  fontSize,
  fontStyle,
  colorTheme,
  textType,
  brand = BRAND_NAMES.NIKE,
) =>
  cx([
    ...getFontStyles(fontFamily, fontStyle, fontSize, textType, brand),
    getFontColor(colorTheme),
  ]);

const createMarkup = body => ({
  __html: body,
});

export const BodyText = ({
  text,
  colorTheme,
  fontFamily,
  fontSize,
  fontStyle,
  textColor,
  customClassName,
  renderAs,
  fontSizeSet, // to avoid this being rendered as unrecognized HTML attribute
  ...props
}) => {
  const brand = useContext(BrandContext);
  const Renderer = renderAs;
  return (
    text && (
      <Renderer
        data-qa="body"
        className={cx([
          customClassName,
          css(fontFamily, fontSize, fontStyle, colorTheme, 'body', brand),
        ])}
        style={setCustomTextColor({ textColor })}
        dangerouslySetInnerHTML={createMarkup(text)}
        {...props}
      />
    )
  );
};
BodyText.displayName = 'BodyText';
BodyText.propTypes = cardStyledTextProperties;
BodyText.defaultProps = {
  text: null,
  colorTheme: 'dark',
  fontFamily: 'base',
  fontSize: 'extra_small',
  fontStyle: 'regular',
  textColor: null,
  renderAs: 'p',
};

export const SubtitleText = ({
  text,
  colorTheme,
  fontFamily,
  fontSize,
  fontStyle,
  textColor,
  customClassName,
}) => {
  const brand = useContext(BrandContext);
  return (
    text && (
      <p
        data-qa="subtitle"
        className={cx([
          styles.subtitle,
          customClassName,
          css(fontFamily, fontSize, fontStyle, colorTheme, 'subTitle', brand),
        ])}
        style={setCustomTextColor({ textColor })}
        dangerouslySetInnerHTML={createMarkup(text)}
      />
    )
  );
};
SubtitleText.displayName = 'Subtitle';
SubtitleText.propTypes = cardStyledTextProperties;

SubtitleText.defaultProps = {
  text: null,
  colorTheme: 'dark',
  fontFamily: 'base',
  fontSize: 'extra_small',
  fontStyle: 'regular',
  textColor: null,
};

export const CaptionText = ({ text, position, colorTheme }) => {
  const brand = useContext(BrandContext);

  return (
    text && (
      <div data-qa="caption" className={cx([styles.caption, position])}>
        <p
          className={cx([
            css('base', 'extra_small', 'regular', colorTheme, 'caption', brand),
          ])}
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      </div>
    )
  );
};
CaptionText.displayName = 'CaptionText';
CaptionText.propTypes = cardStyledTextProperties;

CaptionText.defaultProps = {
  text: null,
  colorTheme: 'dark',
  textColor: null,
};

export const TitleText = ({
  text,
  colorTheme,
  fontFamily,
  fontSize,
  fontStyle,
  textColor,
  customClassName,
  renderAs,
}) => {
  const brand = useContext(BrandContext);
  const Renderer = renderAs;
  if (!text?.trim()) {
    return null;
  }

  return (
    <Renderer
      data-qa="title"
      className={cx([
        styles.title,
        customClassName,
        css(fontFamily, fontSize, fontStyle, colorTheme, 'heading', brand),
      ])}
      style={textColor && setCustomTextColor({ textColor })}
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  );
};
TitleText.displayName = 'TitleText';
TitleText.propTypes = cardStyledTextProperties;

TitleText.defaultProps = {
  text: null,
  colorTheme: 'dark',
  fontFamily: 'marketing',
  fontSize: 'medium',
  fontStyle: 'regular',
  textColor: null,
  renderAs: 'h3',
};
