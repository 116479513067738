import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { PlayFilled } from '@nike/nike-design-system-icons';
import styles from './timestamp.styl';

export const Timestamp = ({
  duration,
  action,
  className,
  timestampWithPlay,
}) => (
  <div onClick={action} className={cx([styles.timestamp, className])}>
    {timestampWithPlay && (
      <PlayFilled
        fillColor="#ffffff"
        width="11"
        height="12"
        viewBox="5 0 20 20"
      />
    )}
    {duration}
  </div>
);

Timestamp.propTypes = {
  duration: PropTypes.string,
  action: PropTypes.func,
  className: PropTypes.string,
  timestampWithPlay: PropTypes.bool,
};
