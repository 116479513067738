import { isWeb } from '@nike/ciclp-config';

export const setQueryParam = (paramName, value) => {
  if (!isWeb() || !paramName) {
    return;
  }

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  queryParams.set(paramName, value);
  url.search = queryParams.toString();
  const newUrl = url.toString();
  window.history.replaceState({ path: newUrl }, '', newUrl);
};
