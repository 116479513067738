/**
 * Returns style object for DOM based on styled text object
 * @param obj card styled text object (cardStyledTextProperties)
 * @returns {*}
 */

const BLACK_COLOR = '#111111';
const BLACK_COLOR_SHORT = '#111';
const WHITE_COLOR = '#FFFFFF';
const WHITE_COLOR_SHORT = '#FFF';
const GREY_COLOR = '#757575';

export const ALLOWED_COLORS = [
  BLACK_COLOR,
  BLACK_COLOR_SHORT,
  WHITE_COLOR,
  WHITE_COLOR_SHORT,
  GREY_COLOR,
];

export const setCustomTextColor = obj => {
  const color = ALLOWED_COLORS.includes(obj?.textColor?.toUpperCase())
    ? obj?.textColor
    : '';
  return color ? { color } : {};
};
