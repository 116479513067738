import { VIDEO_EVENTS } from './constants';
import { formatTime } from './helpers';

export const VIDEO_PLAYER_STATE = {
  isInitiatedByUser: false,
  hasEnded: false,
  playing: false,
  fullScreen: false,
  duration: 0,
  previewStarted: false,
};

export const reducer = (state, { target, type }) => {
  const { player } = target;

  switch (type) {
    case VIDEO_EVENTS.PLAY:
      return {
        ...state,
        hasEnded: false,
        playing: !player.paused(),
      };
    case VIDEO_EVENTS.PAUSE:
      return { ...state, playing: !player.paused() };
    case VIDEO_EVENTS.ENDED:
      return {
        ...state,
        playing: !player.paused(),
        hasEnded: true,
      };
    case VIDEO_EVENTS.FULLSCREENCHANGE:
      return player.options().customProperties.forceFullscreenOnStart
        ? {
            ...state,
            playing: !player.paused(),
            hasEnded: true,
          }
        : state;
    case VIDEO_EVENTS.CANPLAY:
    case VIDEO_EVENTS.LOADEDMETADATA:
      return { ...state, duration: formatTime(player.duration()) };
    case VIDEO_EVENTS.STATECHANGED: {
      const { preset, isInitiatedByUser, previewStarted } = player.state;

      return {
        ...state,
        preset,
        isInitiatedByUser: !!isInitiatedByUser,
        previewStarted: !!previewStarted,
      };
    }
    default:
      return state;
  }
};
