import NikeOneVideoPlayer, {
  VideoAnalyticsEventName,
  Preset,
} from '@nike/nike-one-video-player';
import { find } from 'ramda';
import { videoEventsNames } from '@nike/ciclp-redux-app/analytics/constants';
import constants from '../../../../constants';
import { VIDEO_EVENTS, VIDEO_ANALYTICS_EVENT } from '../../constants';

const BasePlugin = NikeOneVideoPlayer.getPlugin('plugin');

export const isActiveTrack = track => track?.mode === 'showing';

export const getActiveTrack = tracks => find(isActiveTrack, tracks);

export class AnalyticsHandler extends BasePlugin {
  constructor(player, options) {
    super(player, options);
    this.options = options;

    this.playerOptions = this.player.options();
    const eventsToObserve = this.options.eventsToObserve || [
      VIDEO_EVENTS.VOLUMECHANGE,
      VIDEO_EVENTS.PLAY,
      VIDEO_EVENTS.PAUSE,
      VIDEO_EVENTS.SEEKING,
      VIDEO_EVENTS.FULLSCREENCHANGE,
      VIDEO_EVENTS.PREVIEW_STARTED,
      VIDEO_EVENTS.PREVIEW_STOPPED,
    ];
    this.player.on(eventsToObserve, this.handleAnalyticsEvent);
    // the text tracks component does not dispatches events to player,
    // so we need to attach a separate listener
    this.player
      .textTracks()
      .addEventListener(
        VIDEO_EVENTS.TEXT_TRACK_CHANGE,
        this.handleAnalyticsEvent,
      );
  }

  getVideoAnalyticsData() {
    const { player, playerOptions } = this;
    const autoplay = !!player.autoplay() || !!playerOptions.apiAutoplay;
    const activeTrack = getActiveTrack(this.player.textTracks());

    return {
      autoplay,
      currentTime: player.currentTime(),
      fullScreen: player.isFullscreen(),
      loop: !!player.loop(),
      sound: !player.muted(),
      subtitleLanguage: activeTrack?.language || undefined,
      subtitles: isActiveTrack(activeTrack),
      duration: player.duration(),
    };
  }

  handleAnalyticsEvent = event => {
    if (this.skipEvent(event.type)) {
      return;
    }
    const detail = this.prepareVideoAnalyticsEventDetail(event);
    const customEvent = new CustomEvent(
      this.options.analyticsEventName || VIDEO_ANALYTICS_EVENT,
      {
        detail,
      },
    );
    window.dispatchEvent(customEvent);
  };

  getAnalyticsEventName(event) {
    const { player } = this;

    switch (event.type) {
      case VIDEO_EVENTS.VOLUMECHANGE:
        return player.muted()
          ? VideoAnalyticsEventName.mute
          : VideoAnalyticsEventName.unmute;
      case VIDEO_EVENTS.PLAY: {
        if (player.state.hasEnded) {
          return VideoAnalyticsEventName.restart;
        }
        return player.currentTime() < 0.5
          ? VideoAnalyticsEventName.start
          : VideoAnalyticsEventName.played;
      }
      case VIDEO_EVENTS.PAUSE:
        return player.currentTime() === player.duration()
          ? VideoAnalyticsEventName.ended
          : VideoAnalyticsEventName.paused;
      case VIDEO_EVENTS.SEEKING:
        return !player.loop() && VideoAnalyticsEventName.rewound;
      case VIDEO_EVENTS.FULLSCREENCHANGE:
        return player.isFullscreen()
          ? VideoAnalyticsEventName.enteredFullscreen
          : VideoAnalyticsEventName.exitedFullscreen;
      case VIDEO_EVENTS.TEXT_TRACK_CHANGE: {
        const activeTrack = getActiveTrack(player.textTracks());
        return isActiveTrack(activeTrack)
          ? VideoAnalyticsEventName.subtitlesOn
          : VideoAnalyticsEventName.subtitlesOff;
      }
      case VIDEO_EVENTS.PREVIEW_STARTED:
        return videoEventsNames.PREVIEW_STARTED;
      case VIDEO_EVENTS.PREVIEW_STOPPED:
        return videoEventsNames.PREVIEW_STOPPED;
      default:
        return false;
    }
  }

  prepareVideoAnalyticsEventDetail(event) {
    const videoState = this.getVideoAnalyticsData();
    const eventName = this.getAnalyticsEventName(event);
    const videoElement = this.player.el();

    return {
      videoData: videoState,
      eventName,
      placementId: videoElement.closest(constants.ANALYTICS_PLACEMENT_SELECTOR)
        .dataset.analyticsPlacementId,
    };
  }

  skipEvent(eventType) {
    const { player, playerOptions } = this;
    const autoplay = !!player.autoplay() || !!playerOptions.apiAutoplay;
    const isPreviewActive =
      player.state.previewStarted &&
      ![VIDEO_EVENTS.PREVIEW_STARTED, VIDEO_EVENTS.PREVIEW_STOPPED].includes(
        eventType,
      );
    const isLoopedVideo = eventType === VIDEO_EVENTS.SEEKING && player.loop();
    const isPlayEventForAutoplayVideo =
      eventType === VIDEO_EVENTS.PLAY &&
      autoplay &&
      player.state.preset !== Preset.fullControls;
    const isPauseEventOnViewportLeave =
      eventType === VIDEO_EVENTS.PAUSE &&
      autoplay &&
      player.state.preset !== Preset.fullControls;
    const isProgrammaticMuteEvent =
      eventType === VIDEO_EVENTS.VOLUMECHANGE && player.currentTime() === 0;
    const isProgrammaticSeekEvent =
      eventType === VIDEO_EVENTS.SEEKING && player.currentTime() === 0;
    const isProgrammaticTextTrackChange =
      VIDEO_EVENTS.TEXT_TRACK_CHANGE &&
      player.currentTime() === 0 &&
      !player.hasStarted();

    return (
      isPreviewActive ||
      isLoopedVideo ||
      isPlayEventForAutoplayVideo ||
      isPauseEventOnViewportLeave ||
      isProgrammaticMuteEvent ||
      isProgrammaticSeekEvent ||
      isProgrammaticTextTrackChange
    );
  }
}
