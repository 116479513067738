/* eslint-disable no-bitwise */
export const hexToRgb = colorHex => {
  const hex = parseInt(colorHex.replace(/[^a-f0-9]+/i, ''), 16);
  return {
    r: (hex >> 16) & 255,
    g: (hex >> 8) & 255,
    b: hex & 255,
  };
};

export const hexToCssRgba = (colorHex, alpha) => {
  const { r, g, b } = hexToRgb(colorHex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
