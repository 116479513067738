import { NCSS_VERSION } from '../../constants';

export const networking = [
  {
    rel: 'dns-prefetch',
    href: `https://insights-collector.newrelic.com/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://c.static-${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://cdnjs.cloudflare.com/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://secure-store.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://web.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://static.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://unite.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://api.segment.io/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://${process.env.NEXT_PUBLIC_API_HOST_NAME}`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://connect.facebook.net/`,
  },
  {
    rel: 'dns-prefetch',
    href: `https://analytics.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
  },
  {
    rel: 'preload',
    as: 'font',
    href: `https://${process.env.NEXT_PUBLIC_HOST_NAME}/static/ncss/${NCSS_VERSION}/dotcom/fonts/Nike-Futura.woff2`,
    type: 'font/woff2',
    crossOrigin: 'crossorigin',
  },
];
