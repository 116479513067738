import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';

import { isPortraitView } from '../../context/utils';

import { getResolvedImgUrl, getMediaFromRef } from './helpers';

// Return new url to render if width increases by more than 15%
// or the orientation of the page changes
const WIDTH_THRESHOLD = 1.15;

const getResizeObserverCallback =
  ({ portraitURL, landscapeURL, setResolvedUrl }) =>
  entries => {
    entries.forEach(entry => {
      if (!entry.contentRect.width) {
        return;
      }

      const isPortrait = isPortraitView();
      const mediaElement = getMediaFromRef(entry.target);

      const elWidth = Math.floor(entry.contentRect.width);
      const elHeight = Math.floor(entry.contentRect.height);
      const prevWidth = Number(mediaElement.dataset.requestedWidth) || 0;
      const prevIsPortrait =
        mediaElement.dataset.isPortraitImageRequested === 'true';

      const shouldRequestImage =
        elWidth / prevWidth > WIDTH_THRESHOLD || prevIsPortrait !== isPortrait;

      if (!shouldRequestImage) {
        return;
      }
      const isImageElement = mediaElement.hasAttributes(
        'data-landscape-url',
        'data-portrait-url',
      );
      if (isImageElement && mediaElement?.dataset?.lcpLoaded) {
        mediaElement.dataset.lcpLoaded = 'false';
      }

      mediaElement.dataset.requestedWidth = elWidth;
      mediaElement.dataset.requestedHeight = elHeight;
      mediaElement.dataset.isPortraitImageRequested = isPortrait;

      setResolvedUrl(
        getResolvedImgUrl({
          imageRef: entry.target,
          portraitURL,
          landscapeURL,
          width: elWidth,
          height: elHeight,
          dimensionType: { isPortrait },
        }),
      );
    });
  };

export const CloudinaryImage = ({
  children,
  portraitURL,
  landscapeURL,
  ...props
}) => {
  const [resolvedUrl, setResolvedUrl] = useState(null);
  const imageRef = useRef(null);
  const resizeObserverCallback = getResizeObserverCallback({
    portraitURL,
    landscapeURL,
    setResolvedUrl,
  });
///////////////////////
  const debouncedObserverCallback = debounce(resizeObserverCallback, 100);
  const resizeObserverRef = useRef(
    new ResizeObserver(debouncedObserverCallback),
  );
///////////

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    resizeObserver.observe(imageRef.current);

    const entry = {
      contentRect: {
        width: imageRef.current.clientWidth,
        height: imageRef.current.clientHeight,
      },
      target: imageRef.current,
    };

    resizeObserverCallback([entry]);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ width: '100%', height: '100%' }}
      {...props.dataAttrs}
      ref={imageRef}
    >
      {children(resolvedUrl)}
    </div>
  );
};

CloudinaryImage.propTypes = {
  dataAttrs: PropTypes.shape({
    'data-qa': PropTypes.string,
  }),
  children: PropTypes.func.isRequired,
  portraitURL: PropTypes.string.isRequired,
  landscapeURL: PropTypes.string.isRequired,
};
