import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { toUpper } from 'ramda';
import { nike } from '@nike/nike-design-tokens';
import styles from './titleCard.styl';
import { tShirtSize } from '../../shapes';
import GlyphRenderer from './glyphRenderer';
import { TitleMeta } from './titleMeta';

const {
  podiumCdsBreakpointM,
  podiumCdsSizeSpacingXxl,
  podiumCdsSizeSpacingXxxl,
} = nike;

const getBottomMargin = bottomMargin =>
  nike[`podiumCdsSizeSpacing${bottomMargin.replace(/^./, toUpper)}`];

const titleStyles = ({ bottomMargin }) =>
  css`
    margin-top: ${podiumCdsSizeSpacingXxl};
    margin-bottom: ${getBottomMargin(bottomMargin)};
    @media only screen and (min-width: ${podiumCdsBreakpointM}) {
      margin-top: ${podiumCdsSizeSpacingXxxl};
    }
  `;
const TitleWrapper = styled.div(titleStyles);

export const TitleCard = ({
  title,
  glyph,
  glyphSize,
  isArticle,
  lastUpdated,
  lastUpdatedStamp,
  lastUpdatedTemplate,
  readTime,
  readTimeTemplate,
  subTitle,
  body,
  bottomMargin,
  withH1,
  authors,
}) => {
  const Title = withH1 ? 'h1' : 'div';
  const articleProps = isArticle
    ? {
        itemScope: true,
        itemType: 'https://schema.org/Article',
      }
    : {};

  return (
    <TitleWrapper
      bottomMargin={bottomMargin}
      className={cx(styles.titleWrapper, {
        [styles.withLogo]: !!glyph,
      })}
      data-container="title"
      {...articleProps}
    >
      {glyph ? (
        <GlyphRenderer glyph={glyph} size={glyphSize} title={title} />
      ) : (
        <Title
          data-qa="title"
          className={cx('headline-1', styles.title)}
          itemProp="headline"
        >
          {title}
        </Title>
      )}
      {!!subTitle && (
        <h5
          data-qa="subtitle"
          className={cx({
            'headline-4 pb1-sm': !!glyph,
            'body-2 text-color-secondary': !glyph,
            [styles.noGlyphSubtitle]: !glyph,
          })}
        >
          {subTitle}
        </h5>
      )}
      {!!body && <p className="responsive-body-2-1 mt6-sm">{body}</p>}
      {isArticle && (
        <TitleMeta
          authors={authors}
          lastUpdated={lastUpdated}
          lastUpdatedStamp={lastUpdatedStamp}
          lastUpdatedTemplate={lastUpdatedTemplate}
          readTime={readTime}
          readTimeTemplate={readTimeTemplate}
        />
      )}
    </TitleWrapper>
  );
};

TitleCard.propTypes = {
  title: PropTypes.string.isRequired,
  glyph: PropTypes.string,
  glyphSize: PropTypes.string,
  subTitle: PropTypes.string,
  body: PropTypes.string,
  lastUpdated: PropTypes.string,
  lastUpdatedStamp: PropTypes.string,
  lastUpdatedTemplate: PropTypes.string,
  isArticle: PropTypes.bool,
  bottomMargin: tShirtSize,
  withH1: PropTypes.bool,
  authors: PropTypes.shape({
    roleTitle: PropTypes.string,
    profiles: PropTypes.array,
  }),
  readTime: PropTypes.number,
  readTimeTemplate: PropTypes.string,
};

TitleCard.defaultProps = {
  bottomMargin: 'xl',
  withH1: false,
  isArticle: false,
};
