import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectors, CARD_ACTION_TYPES } from '@nike/ciclp-redux-app';
import NavigationLink from '../navigationLink';
import { useIdentity } from '../identity';
import { setQueryParam } from '../../helpers/setQueryParam';

// need that hook to avoid button flickering after unite is initialized
const useDelayPropertyChange = (prop, delay) => {
  const [property, setProperty] = useState(prop);

  useEffect(() => {
    setTimeout(() => {
      setProperty(prop);
    }, delay);
  }, [prop, delay]);

  return property;
};

const useMemberOnlyProps = props => {
  const isLoggedIn = useIdentity(global.webShellClient?.identity.getIsLoggedIn);
  const isIdentityInitialized = useIdentity(
    global.webShellClient?.identity.getInitialized,
  );
  const loginLabel = useSelector(state =>
    selectors.translationSelector(state, 'member_only_login_label'),
  );
  const showPlaceholder = useDelayPropertyChange(!isIdentityInitialized, 100);
  if (!props.memberOnly) {
    return {};
  }

  const isMemberOnlyNotLogged = !isLoggedIn;
  const isButtonType = props.actionType === CARD_ACTION_TYPES.BUTTON;
  const renderAs =
    isMemberOnlyNotLogged || showPlaceholder ? 'button' : props.renderAs;
  const isShopMyInterestsAction = props.destinationId === '#interests';

  const handleClick = params => {
    if (isMemberOnlyNotLogged) {
      // Based on Shop My Interests solution (see CICLP-3499) query param needs to be added to URL before redirecting to sign in flow
      if (isShopMyInterestsAction) {
        setQueryParam('smi_welcome', 'true');
        setQueryParam('smi_scrollPosition', document.documentElement.scrollTop);
      }
      window.webShellClient.identity.signIn();
    }
    props?.handleClick?.(params);
  };

  return {
    allowAnalytics: true,
    isMemberOnlyNotLogged,
    isShopMyInterestsAction,
    showPlaceholder,
    renderAs,
    isButtonType,
    loginLabel,
    handleClick,
  };
};

// eslint-disable-next-line react/display-name
const withMemberOnlyHoc = Component => props => {
  const memberOnlyProps = useMemberOnlyProps(props);

  const getUrl = url =>
    (!memberOnlyProps.isMemberOnlyNotLogged && (!url ? '#' : url)) || undefined;

  const getActionText = (text, wrapWithLoginLabel) => {
    if (
      memberOnlyProps.isMemberOnlyNotLogged &&
      wrapWithLoginLabel &&
      !memberOnlyProps.isShopMyInterestsAction
    ) {
      return `${memberOnlyProps.loginLabel} ${text}`;
    }
    return text;
  };

  return (
    <Component
      {...props}
      getUrl={getUrl}
      getActionText={getActionText}
      {...memberOnlyProps}
    />
  );
};

export default withMemberOnlyHoc;

export const withMemberOnlyShape = {
  memberOnly: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  loginLabel: PropTypes.string,
  renderAs: PropTypes.oneOf(['button', 'a', NavigationLink]),
  isButtonType: PropTypes.bool,
  getUrl: PropTypes.func,
  getActionText: PropTypes.func,
};
