import PropTypes from 'prop-types';
import { BUYING_TOOLS_STATUS, CARD_TYPES } from '@nike/ciclp-redux-app';
import constants from '../constants';

export const THEMES = ['dark', 'light'];

export const FONT_STYLES = ['regular', 'medium'];

export const VIDEO_CONTROL_OPTIONS = PropTypes.oneOf([
  constants.NONE_CONTROL_OPTIONS,
  constants.FULL_CONTROL_OPTIONS,
]);

export const FONT_SIZES = [
  'extra_small',
  'small',
  'medium',
  'large',
  'extra_large',
];

export const FONT_FAMILIES = ['marketing', 'brand', 'base'];

export const HORIZONTAL_LOCATIONS = ['start', 'center', 'end'];
export const VERTICAL_LOCATIONS = ['start', 'center', 'end', 'after'];

export const IMAGE_SIZES = [
  'extra_small',
  'small',
  'medium',
  'large',
  'extra_large',
  'maintain',
];

export const actionTypeShape = PropTypes.oneOf([
  'cta_buying_tools',
  'button',
  'link',
  'card_link',
  'promo',
  'share',
  'stacked_cta',
]).isRequired;

export const actionButtonsShape = PropTypes.arrayOf(
  PropTypes.shape({
    actionText: PropTypes.string,
    actionType: actionTypeShape,
    destinationId: PropTypes.string,
    destinationType: PropTypes.string,
    id: PropTypes.string,
    openInNewTab: PropTypes.bool,
  }),
);

export const actionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    actionType: actionTypeShape,
  }),
).isRequired;

export const imageShape = PropTypes.shape({
  aspectRatio: PropTypes.number,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
});

export const textLocationShape = PropTypes.shape({
  horizontal: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
});

export const cardStyledTextProperties = {
  text: PropTypes.string,
  id: PropTypes.string,
  fontFamily: PropTypes.oneOf(FONT_FAMILIES),
  fontSize: PropTypes.oneOf(FONT_SIZES),
  fontStyle: PropTypes.oneOf(FONT_STYLES),
  textColor: PropTypes.string,
  customClassName: PropTypes.string,
  renderAs: PropTypes.oneOf(['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export const cardStyledTextShape = PropTypes.shape(cardStyledTextProperties);

export const cardOverlayPositionsShape = PropTypes.shape({
  horizontal: PropTypes.oneOf(HORIZONTAL_LOCATIONS),
  vertical: PropTypes.oneOf(VERTICAL_LOCATIONS),
});

export const genericCardDataProperties = {
  body: PropTypes.string,
  colorTheme: PropTypes.string.isRequired,
  style: PropTypes.shape({
    defaultStyle: PropTypes.shape({
      textColor: PropTypes.string,
      textLocation: textLocationShape,
    }),
  }),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export const genericCardDataShape = PropTypes.shape(genericCardDataProperties);

export const colorThemeShape = PropTypes.oneOf(THEMES);

export const imageSizesShape = PropTypes.oneOf([...IMAGE_SIZES, undefined]);

export const arrayToObject = arr =>
  arr.reduce((obj, val) => ({ ...obj, [val]: val }), {});

export const focalPointShape = PropTypes.shape({
  horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  vertical: PropTypes.oneOf(['top', 'middle', 'bottom']),
  hardCrop: PropTypes.bool,
});

export const teamSelectorItemsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string.required,
    image: PropTypes.string.required,
    openInNewTab: PropTypes.bool,
    destinationType: PropTypes.string,
  }),
);

export const componentTypesShape = PropTypes.oneOf([
  'image',
  'video',
  'text',
  'section_headline',
  'external_collection',
  'product_wall',
  'product_recommender',
  'top_trending',
  'merch_menu',
  'local_menu',
  'team_selector_menu',
  'filmstrip',
  'stacked',
  'related_content',
  'slideshow',
]);

export const sectionHeadlineShape = PropTypes.shape({
  containerType: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  actions: actionButtonsShape,
  textColor: PropTypes.string,
});

export const gallerySlideShape = {
  id: PropTypes.string.isRequired,
  cardLinkMemberOnly: PropTypes.bool,
  cardLinkUrl: PropTypes.string,
  colorTheme: colorThemeShape,
  openInNewTab: PropTypes.bool,
  subtitleProps: cardStyledTextShape,
  titleProps: cardStyledTextShape,
  bodyProps: cardStyledTextShape,
  textLocation: textLocationShape,
  isTextBelowImage: PropTypes.bool,
  destinationType: PropTypes.string,
  sectionHeadlineTitle: PropTypes.string,
  filmstripId: PropTypes.string,
  cardLinkId: PropTypes.string,
  analyticsItemId: PropTypes.string,
  cardLinkCollectionId: PropTypes.string,
};

export const enhancedProductCardShape = {
  TextBadgeComponent: PropTypes.elementType,
  altText: PropTypes.string,
  aspectRatio: PropTypes.string,
  country: PropTypes.string,
  detailsClass: PropTypes.string,
  formattedListPrice: PropTypes.string,
  formattedSalePrice: PropTypes.string,
  imgUrl: PropTypes.string,
  isMemberExclusive: PropTypes.bool,
  isNikeByYou: PropTypes.bool,
  isOnSale: PropTypes.bool,
  overlayText: PropTypes.string,
  productId: PropTypes.string.isRequired,
  productThreadId: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  textBadge: PropTypes.string,
  title: PropTypes.string,
  translations: PropTypes.objectOf(PropTypes.string),
  url: PropTypes.string,
};

export const productSlideShape = {
  ...enhancedProductCardShape,
  labels: PropTypes.shape({
    memberAccessLabel: PropTypes.string,
    nikeByYouLabel: PropTypes.string,
    nikeByYouText: PropTypes.string,
  }),
};

export const productContainerLabelsShape = {
  memberAccessLabel: PropTypes.string,
  nikeByYouLabel: PropTypes.string,
  memberExclusiveText: PropTypes.string,
  nikeByYouText: PropTypes.string,
};

export const productContainerShape = {
  labels: PropTypes.shape(productContainerLabelsShape),
  containerType: PropTypes.oneOf([
    CARD_TYPES.EXTERNAL_COLLECTION,
    CARD_TYPES.PRODUCT_WALL,
    CARD_TYPES.PRODUCT_RECOMMENDER,
    CARD_TYPES.PRODUCT_RECOMMENDER_TAXONOMY,
    CARD_TYPES.SNKRS_DROPS,
  ]),
  sectionHeadline: sectionHeadlineShape,
  slides: PropTypes.arrayOf(PropTypes.shape(productSlideShape)),
  slidesUpcoming: PropTypes.arrayOf(PropTypes.shape(productSlideShape)),
  dataQa: PropTypes.string,
  isLoading: PropTypes.bool,
  maxResults: PropTypes.number,
};

export const videoCardShape = {
  withH1: PropTypes.bool,
  bodyProps: cardStyledTextShape,
  actionButtons: actionButtonsShape,
  cardLinkId: PropTypes.string,
  cardLinkUrl: PropTypes.string,
  openInNewTab: PropTypes.bool,
  colorTheme: colorThemeShape,
  cardLinkMemberOnly: PropTypes.bool,
  subtitleProps: cardStyledTextShape,
  textLocation: textLocationShape,
  titleProps: cardStyledTextShape,
  loop: PropTypes.bool.isRequired,
  videoId: PropTypes.string.isRequired,
  portraitVideoId: PropTypes.string,
  controlOptions: VIDEO_CONTROL_OPTIONS,
  autoPlay: PropTypes.bool.isRequired,
  portraitPosterUrl: PropTypes.string,
  landscapePosterUrl: PropTypes.string,
  destinationType: PropTypes.string,
  locale: PropTypes.string,
  language: PropTypes.string,
  analyticsItemId: PropTypes.string,
  actionText: PropTypes.node,
  customClass: PropTypes.string,
  watchCtaButtonText: PropTypes.string,
  translationsOffText: PropTypes.string,
};

export const buyingToolsIngredientsShape = {
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string,
  status: PropTypes.oneOf([
    BUYING_TOOLS_STATUS.SOLD_OUT,
    BUYING_TOOLS_STATUS.COMING_SOON,
    BUYING_TOOLS_STATUS.AVAILABLE,
  ]).isRequired,
  styleColor: PropTypes.string.isRequired,
};

export const notifyMeDialogShape = {
  cancelLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  dialogText: PropTypes.string,
  dialogTitle: PropTypes.string,
  emailLabel: PropTypes.string,
  formError: PropTypes.string,
  notifyMeLabel: PropTypes.string,
  postSubmitDialogText: PropTypes.string,
  postSubmitDialogTitle: PropTypes.string,
  shopLabel: PropTypes.string,
  soldOutLabel: PropTypes.string,
  submitLabel: PropTypes.string,
};

export const assetsAspectRatiosShape = PropTypes.shape({
  portrait: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  landscape: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const captionShape = PropTypes.shape({
  position: PropTypes.oneOf(['overlay', 'below']),
  colorTheme: PropTypes.oneOf(['light', 'dark']),
  text: PropTypes.string,
});

export const paginationShape = {
  totalPages: PropTypes.number,
  pageNumber: PropTypes.number,
  anchor: PropTypes.string,
  translations: PropTypes.shape({
    paginationNext: PropTypes.string,
    paginationPrevious: PropTypes.string,
    paginationCurrent: PropTypes.string,
  }),
};

export const tShirtSize = PropTypes.oneOf([
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'xxxl',
  'xxxxl',
]);
