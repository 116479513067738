import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TitleComponentBase, LinkComponentBase } from './LocalNavItem';

export const LocalNavMenu = ({
  titleProps,
  items,
  TitleComponent,
  LinkComponent,
  className,
  customClassName,
  placeHolderClass,
}) =>
  titleProps?.text || items?.length ? (
    <div className={clsx([className, customClassName, placeHolderClass])}>
      <div className="local-nav-wrapper">
        {titleProps?.text && (
          <TitleComponent
            {...titleProps}
            className={clsx(['local-nav-title', titleProps.className])}
          />
        )}
        {!!items?.length && (
          <ul className="local-nav-list">
            {items.map((item, index) => (
              <li className="local-nav-menu-item" key={index}>
                <LinkComponent {...item} />
              </li>
            ))}
          </ul>
        )}
        {titleProps?.text && (
          <TitleComponent
            {...titleProps}
            className={clsx([
              'local-nav-title local-nav-title_hidden',
              titleProps.className,
            ])}
            renderAs="div"
          />
        )}
      </div>
    </div>
  ) : null;

LocalNavMenu.propTypes = {
  /** Component to render title in local nav */
  TitleComponent: PropTypes.elementType,
  /** Component to render links in menu  */
  LinkComponent: PropTypes.elementType,
  /** Class name(s) to apply to the root element. */
  className: PropTypes.string,
  /** Custom class name(s) to apply to the root element. */
  customClassName: PropTypes.string,
  /** Class name applied when navigation is sticky to avoid content jumping */
  placeHolderClass: PropTypes.string,
  /** Props that are passed to title component */
  titleProps: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  }),
  /** Props that are passed to title component */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string.isRequired,
      className: PropTypes.string,
    }),
  ),
};

LocalNavMenu.defaultProps = {
  TitleComponent: TitleComponentBase,
  LinkComponent: LinkComponentBase,
};
