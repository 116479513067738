import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import { Jordan } from '@nike/nike-design-system-icons';
import cx from 'clsx';
import { BRAND_NAMES } from '@nike/ciclp-config';
import NavigationLink from '../navigationLink';
import { BrandContext } from '../../context';

import styles from './localNav.styl';
import LocalNavDropdown from './localNavDropdown';

const renderTitleByBrand = (navTitle, isJordanBrand) => {
  if (isJordanBrand) {
    // viewBox, width and height values are set as NDS icon is not edge-to-edge inside SVG
    return (
      <Jordan
        className={styles.logoImage}
        title={BRAND_NAMES.JORDAN}
        viewBox="4 2.5 16 17"
        width="91px"
        height="84px"
      />
    );
  }
  return navTitle;
};

export const LocalNav = ({
  title,
  items,
  containerClass,
  withH1,
  landmarkLabel,
}) => {
  const brand = useContext(BrandContext);
  const isJordanBrand = brand === BRAND_NAMES.JORDAN;
  const Title = withH1 ? 'h1' : 'div';

  return (
    <Sticky
      enableTransforms={false}
      className={cx(styles.stickyWrapper, {
        [styles.stickyWrapperJordan]: isJordanBrand,
      })}
      activeClass={cx('active', {
        [styles.stickyWrapperBrandActive]: isJordanBrand,
      })}
    >
      <nav
        aria-label={landmarkLabel}
        className={cx(
          'ncss-container',
          'fixed-fluid',
          styles.localNavWrapper,
          {
            [styles.brand]: isJordanBrand,
            [styles.jordan]: isJordanBrand,
          },
          containerClass,
        )}
      >
        {title && (
          <Title className={cx(styles.title, 'headline-3')}>
            {renderTitleByBrand(title, isJordanBrand)}
          </Title>
        )}
        <div className={styles.localNavContainer}>
          <ul className={styles.localNav} role="menu">
            {items.map((item, index) => (
              <li key={item.id} role="none">
                {item.links?.length > 0 ? (
                  <LocalNavDropdown content={item} index={index + 1} />
                ) : (
                  <NavigationLink
                    href={item.url}
                    data-analytics-action-id={item.id}
                    className={cx(styles.localNavItem)}
                    aria-label={item.label}
                    role="menuitem"
                  >
                    {item.label}
                  </NavigationLink>
                )}
              </li>
            ))}
          </ul>
        </div>
        {/** This title is needed to center local nav menu using css */}
        {title && (
          <div className={styles.title} hidden aria-hidden="true">
            {renderTitleByBrand(title, isJordanBrand)}
          </div>
        )}
      </nav>
    </Sticky>
  );
};

LocalNav.propTypes = {
  title: PropTypes.string.isRequired,
  landmarkLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      label: PropTypes.string,
      destinationType: PropTypes.string,
      openInNewTab: PropTypes.bool,
    }),
  ).isRequired,
  containerClass: PropTypes.string,
  withH1: PropTypes.bool,
};
