import { covertMinToISOduration, getCardObject } from '../helpers/helpers';
import {
  getHowToTool,
  getMonetaryAmount,
  getHowToSupply,
  getHowToStep,
  getImage,
} from '../helpers/structuredDataHelpers';

export const getMetaData = metaBoxCard => {
  const metaBoxCardObject = getCardObject(metaBoxCard);
  const totalTime = covertMinToISOduration(metaBoxCardObject?.timeRequired);
  const currency = metaBoxCardObject?.estimatedCostCurrency;
  const cost = metaBoxCardObject?.estimatedCost;
  const hasCostData = !!currency && !!cost;

  const estimatedCost = hasCostData
    ? getMonetaryAmount({ currency, cost })
    : undefined;

  return {
    totalTime,
    estimatedCost,
  };
};

export const getToolsAndSupply = toolsAndSupplyCard => {
  const { tools = [], supplies = [] } = getCardObject(toolsAndSupplyCard) ?? {};
  const tool = tools.map(toolItem => getHowToTool(toolItem.text));
  const supply = supplies.map(supplyItem => getHowToSupply(supplyItem.text));

  return {
    tool,
    supply,
  };
};

export const getSteps = listicleCard => {
  // extract the steps from the first listicle card
  const { slides: steps = [] } = getCardObject(listicleCard) ?? {};
  return steps
    .filter(step => step.isListicleHeadline)
    .map(({ seo: { title, text, imageURL } }) =>
      getHowToStep({ name: title, imageURL, text }),
    );
};

export const getGeneralData = coverCard => ({
  name: coverCard?.title,
  description: coverCard?.body,
  ...(coverCard?.imageURL && { image: getImage(coverCard?.imageURL) }),
});

export const getHowToData = (
  coverCard,
  metaDataCard,
  toolAndSupplyCard,
  listicleCard,
) => ({
  ...getGeneralData(coverCard),
  ...getMetaData(metaDataCard),
  ...getToolsAndSupply(toolAndSupplyCard),
  step: getSteps(listicleCard),
});
