import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import styles from './imageMedia.styl';
import { focalPointShape } from '../../shapes';

export const VideoElement = ({
  onLoad,
  focalPoint,
  url,
  isImageLoaded,
  innerRef,
}) => (
  <video
    onCanPlayThrough={onLoad}
    autoPlay
    loop
    playsInline
    muted
    // innerRef is needed for React Waypoint
    ref={innerRef}
    src={url}
    className={cx(
      styles[`v-${focalPoint?.vertical ?? 'center'}`],
      styles[`h-${focalPoint?.horizontal ?? 'center'}`],
      {
        [styles.withHardCrop]: focalPoint?.hardCrop,
      },
      isImageLoaded && styles.loadedImage,
    )}
  />
);

VideoElement.propTypes = {
  innerRef: PropTypes.func,
  isImageLoaded: PropTypes.bool,
  onLoad: PropTypes.func,
  url: PropTypes.string,
  focalPoint: focalPointShape,
};
