import React from 'react';
import PropTypes from 'prop-types';
import styles from '../relatedFilmstrip.styl';
import SectionHeadline from '../../sectionHeadline';
import { sectionHeadlineShape } from '../../../shapes';

export const RelatedTwoInRow = ({
  sectionHeadline,
  headlineAnalyticsId,
  filmstripSlides,
}) => {
  return (
    <div className={styles.twoInRowWrapper}>
      <SectionHeadline
        {...sectionHeadline}
        analyticsItemId={headlineAnalyticsId}
        containerClassName={styles.mobileHeadline}
        className={styles.headlineText}
      />
      <div className={styles.twoInRow}>
        {filmstripSlides.map((slide, index) => (
          <div className={styles.twoInRowItem} key={index}>
            {slide}
          </div>
        ))}
      </div>
    </div>
  );
};

RelatedTwoInRow.propTypes = {
  sectionHeadline: sectionHeadlineShape,
  analyticsItemId: PropTypes.string,
  headlineAnalyticsId: PropTypes.string,
  filmstripSlides: PropTypes.arrayOf(PropTypes.node),
};
