import cx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './banner.styl';

export const BannerSlide = ({
  title,
  msg,
  actions,
  onSlideFocus,
  onSlideBlur,
  isHidden,
}) => (
  <li
    className={styles.bannerSlide}
    onFocus={onSlideFocus}
    onBlur={onSlideBlur}
    aria-hidden={isHidden}
  >
    <span className={styles.bannerTitle}>{title}</span>
    <div
      className={styles.bannerSubtitle}
      dangerouslySetInnerHTML={{
        __html: msg?.replace(/<a\s/g, `<a class=${styles.bannerLink} `),
      }}
    />
    {actions
      ? actions.map((action, index) => (
          <a
            key={index}
            href={action.url}
            className={cx(styles.bannerLink, action.className)}
            style={action.style}
          >
            {action.text}
          </a>
        ))
      : null}
  </li>
);

BannerSlide.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  onSlideBlur: PropTypes.func,
  onSlideFocus: PropTypes.func,
  isHidden: PropTypes.bool,
};
