import React from 'react';
import cx from 'clsx';
import { mergeButtons, VideoMedia } from '../videoMedia';
import CardOverlay from '../cardOverlay';
import { CardLink } from '../cardLink';
import styles from './videoCard.styl';

import { BodyText, SubtitleText, TitleText } from '../textOverlay';
import { MultiCta } from '../ctaButton';

import { videoCardShape } from '../../shapes';
import {
  isCardOverlayNeeded,
  isTextNotEmpty,
  isTextPositionAfter,
} from '../../helpers/mediaContent';

export const VideoCardLandingPages = ({
  bodyProps,
  withH1,
  actionButtons,
  cardLinkId,
  cardLinkUrl,
  openInNewTab,
  colorTheme,
  subtitleProps,
  textLocation,
  titleProps,
  analyticsItemId,
  loop,
  videoId,
  portraitVideoId,
  controlOptions,
  autoPlay,
  portraitPosterUrl,
  landscapePosterUrl,
  watchCtaButtonText,
  destinationType,
  locale,
  customClass,
  cardLinkMemberOnly,
  cardLinkCollectionId,
  assetsAspectRatios,
}) => {
  const isCardOverlayExist = isCardOverlayNeeded({
    titleProps,
    subtitleProps,
    bodyProps,
    actionButtons,
  });

  const hasTextPositionAfter = isTextPositionAfter(textLocation);
  return (
    <div
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      data-qa="video-card"
      className={cx(styles.videoCardContainer, {
        [styles.isTextPositionAfter]: hasTextPositionAfter,
      })}
    >
      <VideoMedia
        loop={loop}
        videoId={videoId}
        portraitVideoId={portraitVideoId}
        controlOptions={controlOptions}
        autoPlay={autoPlay}
        portraitPosterUrl={portraitPosterUrl}
        landscapePosterUrl={landscapePosterUrl}
        locale={locale}
        previewOnHover
        customClass={customClass}
        isCardOverlayExist={isCardOverlayExist}
        isTextPositionAfter={hasTextPositionAfter}
        pauseOnLeave={!autoPlay}
        watchCtaButtonText={watchCtaButtonText}
        assetsAspectRatios={assetsAspectRatios}
      >
        {({ buttonProps }) => {
          return (
            <>
              <CardOverlay renderAs="div" position={textLocation}>
                {subtitleProps?.text !== '' && (
                  <SubtitleText {...subtitleProps} />
                )}
                {titleProps?.text !== '' && (
                  <TitleText
                    {...{
                      ...titleProps,
                      ...(withH1 && { renderAs: 'h1' }),
                    }}
                  />
                )}
                {bodyProps?.text !== '' && <BodyText {...bodyProps} />}
                <MultiCta
                  actionButtons={mergeButtons(actionButtons, buttonProps)}
                  colorTheme={colorTheme}
                  addTopSpacing={isTextNotEmpty(
                    bodyProps.text,
                    subtitleProps.text,
                    titleProps.text,
                  )}
                />
              </CardOverlay>
              {cardLinkId && (
                <CardLink
                  actionId={cardLinkId}
                  memberOnly={cardLinkMemberOnly}
                  collectionId={cardLinkCollectionId}
                  destinationType={destinationType}
                  destinationUrl={cardLinkUrl}
                  openInNewTab={openInNewTab}
                  ariaLabel={`${subtitleProps?.text} ${titleProps?.text}`}
                  tabbable={!actionButtons?.length}
                />
              )}
            </>
          );
        }}
      </VideoMedia>
    </div>
  );
};

VideoCardLandingPages.propTypes = {
  ...videoCardShape,
};
