import React from 'react';
import PropTypes from 'prop-types';
import styles from '../relatedFilmstrip.styl';
import SectionHeadline from '../../sectionHeadline';
import { sectionHeadlineShape } from '../../../shapes';

export const RelatedList = ({
  sectionHeadline,
  headlineAnalyticsId,
  filmstripSlides,
}) => {
  return (
    <div className={styles.mobileContainer}>
      <SectionHeadline
        {...sectionHeadline}
        analyticsItemId={headlineAnalyticsId}
        containerClassName={styles.mobileHeadline}
        className={styles.headlineText}
      />
      <div className={styles.mobileSlides}>{filmstripSlides}</div>
    </div>
  );
};

RelatedList.propTypes = {
  sectionHeadline: sectionHeadlineShape,
  analyticsItemId: PropTypes.string,
  headlineAnalyticsId: PropTypes.string,
  filmstripSlides: PropTypes.arrayOf(PropTypes.node),
};
