import React from 'react';
import dynamic from 'next/dynamic';
import { CARD_ACTION_DESTINATION_TYPES } from '@nike/ciclp-redux-app';
import { CardLink, cardLinkShape } from './cardLink';

const ShopTheLook = dynamic(() =>
  import('../../containers/shopTheLook').then(mod => mod.ShopTheLook),
);

const IframeModal = dynamic(() =>
  import('../iframeModal').then(mod => mod.IframeModal),
);

export const CardLinkContainer = props => {
  if (props.destinationType === CARD_ACTION_DESTINATION_TYPES.POPUP) {
    return (
      <IframeModal src={props.destinationUrl}>
        {({ onClick }) => (
          <CardLink
            {...props}
            handleClick={onClick}
            renderAs="button"
            allowAnalytics
          />
        )}
      </IframeModal>
    );
  }

  if (
    props.destinationType === CARD_ACTION_DESTINATION_TYPES.PRODUCT_COLLECTION
  ) {
    return (
      <ShopTheLook
        analyticsItemId={props.analyticsItemId}
        actionText={props.actionText}
        id={props.actionId}
      >
        {({ handleOpenModal }) => (
          <CardLink
            {...props}
            renderAs="button"
            handleClick={handleOpenModal}
            allowAnalytics
          />
        )}
      </ShopTheLook>
    );
  }

  return <CardLink {...props} />;
};

CardLinkContainer.propTypes = cardLinkShape;
