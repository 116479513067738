import { useSelector, shallowEqual } from 'react-redux';
import { selectors } from '@nike/ciclp-redux-app';
import React, { useState } from 'react';
import { useInterval } from '@nike/nike-design-system-components';
import { BannerSlide } from './bannerSlide';
import { useIdentity } from '../identity';

import styles from './banner.styl';

const BANNER_AUTOPLAY_INTERVAL = 6000;

export const Banner = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const banners = useSelector(selectors.bannerNodesSelector, shallowEqual);
  const isSwooshUser = useIdentity(
    global.webShellClient?.identity.getIsSwooshUser,
  );
  const hasBanners = Boolean(banners?.length);

  const onSlideFocus = () => {
    setIsPaused(true);
  };

  const onSlideBlur = () => {
    setIsPaused(false);
  };

  const changeSlide = () => {
    let nextSlideIndex = activeSlideIndex + 1;
    if (nextSlideIndex === banners?.length) {
      nextSlideIndex = 0;
    }
    setActiveSlideIndex(nextSlideIndex);
  };

  const shouldAutoplay = !isPaused && hasBanners;
  useInterval(changeSlide, shouldAutoplay ? BANNER_AUTOPLAY_INTERVAL : null);

  if (isSwooshUser) {
    return null;
  }

  return (
    <div className={styles.wrapper} data-qa="banner">
      <ul
        className={styles.banner}
        style={{ transform: `translateX(-${100 * activeSlideIndex}%)` }}
      >
        {banners?.map((banner, bannerIndex) => (
          <BannerSlide
            key={banner.id}
            {...banner}
            onSlideFocus={onSlideFocus}
            onSlideBlur={onSlideBlur}
            isHidden={activeSlideIndex !== bannerIndex}
          />
        ))}
      </ul>
    </div>
  );
};
