import newrelic from 'newrelic';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { useStore, Provider } from 'react-redux';
////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
/////////
import { getUserFromCookie, setAnonymousId } from '@nike/ciclp-utils/cookies';
import RootContainer, {
  OptimizelyResolver,
} from '@nike/ciclp-react-components';
import {
  ROOT_ELEMENT_ID,
  NIKE_SHOP_ELEMENT_ID,
  FEATURE_FLAGS,
  BRAND_NAMES,
  PAGE_TYPES,
} from '@nike/ciclp-config';

// CICLP-2968 adding that polyfill as part of a bundle to be sure that polyfill is in place
// at the time user will start scrolling
/////////////////////
import 'requestidlecallback-polyfill';
/////////

import {
  actions,
  routeNames,
  selectors,
  getExtendedFetchFn,
  logLevels,
} from '@nike/ciclp-redux-app';
import getMetadata from '../helpers/metadata';
import SEOMetaTags from '../components/SEOMetaTags';
import { wrapper } from './_app';

////////////////////
////////
/////////////////////////
////////////////////////
/////////////////////////
//////////////////
/////////////////////////////////
////////////////////////
//////////////////////////////
//////////////////////////////
///////////////////////////
///////////////////////////////
////////////////////////
//////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////

import { loadPolyfills } from '../helpers/polyfills';

const isAdobeTargetEnabled = process.env.ADOBE_TARGET_ENABLED === 'true';
const dataCaptureScriptEnabled =
  process.env.NEXT_PUBLIC_ENABLE_DATA_CAPTURE_SCRIPT === 'true'; // C4CGCCOM-2668 Disable data capture script in CG

export const getServerSideProps = wrapper.getServerSideProps(
  store =>
    async ({ req, query, res }) => {
      // creating a separate instance of logger for each request to do not loose traceId
      const logger = new Logger(
        getServerTransports({ externalLogger: req.logger, newrelic }),
      );
      const lpContext = {
        store,
        query,
        logger,
      };

      queryParamsSanitizerMiddleware(req);
      // call our old express middlewares
      featureFlagsMiddleware(req, lpContext);

      lpContext.fetchFn = getExtendedFetchFn(store.getState());

      // all the other "middlewares" that rely on feat flags should be added after this
      let threadData;
      try {
        await urlAnalysisMiddleware(req, lpContext);
        threadData = await resolveExperienceMiddleware(lpContext);
      } catch (exception) {
        const {
          name,
          message,
          stack,
          details,
          logLevel = logLevels.ERROR,
        } = exception;
        logger[logLevel.toLowerCase()]?.({
          name,
          message,
          stack,
          details,
        });

        const shouldRenderNotFoundTemplate =
          exception instanceof ServiceError ||
          exception instanceof PageNotFoundException;

        if (shouldRenderNotFoundTemplate) {
          store.dispatch(
            actions.routerActions.update({ name: routeNames.NOT_FOUND }),
          );
        } else {
          const redirect = getRedirectDestination(store, exception);
          return {
            redirect,
          };
        }
      }

      try {
        await translationsMiddleware(lpContext);
        const resolvedThreadData = await resolveAboveTheFoldVariation(
          req,
          lpContext,
          threadData,
        );
        await prepareReduxStoreMiddleware(lpContext, resolvedThreadData);
      } catch (error) {
        const {
          name,
          message,
          stack,
          details,
          logLevel = logLevels.ERROR,
        } = error;
        logger[logLevel.toLowerCase()]?.({
          name,
          message,
          stack,
          details,
        });
      }

      cacheMiddleware(res, lpContext);

      responseStatusMiddleware(res, lpContext);

      const state = store.getState();
      const featureFlags = selectors.featureFlagsSelector(state);
      const navDisabled = !!featureFlags[FEATURE_FLAGS.DISABLE_GLOBAL_NAV];
      const uniteDisabled = !!featureFlags[FEATURE_FLAGS.DISABLE_UNITE];
      const uiExperimentsDisabled =
        !!featureFlags[FEATURE_FLAGS.DISABLE_UI_EXPERIMENTS];

      const pageType = selectors.pageTypeSelector(state);

      // The check is needed because of CICLP-2031
      const isMarketingDisabled =
        pageType === PAGE_TYPES.SIZE_CHART ||
        !!featureFlags[FEATURE_FLAGS.DISABLE_MC];
      /**
       * Akamai doesn't recommend to process ESI on 5xx responses (see https://jira.nike.com/browse/CDN-7264).
       * SEO requirements are to return HTTP 500 for homepage in case it's content is not found, so
       * in this case global nav should be fetched without ESI.
       */
      const isEsiEnabled =
        process.env.WS_ENABLE_ESI === 'true' &&
        res.statusCode !== HP_NOT_FOUND_RESPONSE_STATUS;
      const requestLocale = serverLocale.parseRequestLocale({ req });
      let optimizelyData = null;

      if (!uiExperimentsDisabled && !isAdobeTargetEnabled) {
        optimizelyData = await getOptimizelyDatafile(lpContext);
      }
      const globalContentFeatures =
        selectors.globalContentFeaturesSelector(state);
      return {
        props: {
          document: {
            nav: {
              enabled: !navDisabled,
            },
            unite: {
              enabled: !uniteDisabled,
            },
            esi: {
              enabled: isEsiEnabled,
            },
            // we load it ourselves because we need some custom events triggered for mcSaga
            dataCaptureMarketing: {
              enabled: !isMarketingDisabled && dataCaptureScriptEnabled,
            },
            lpConfig: {
              scripts: JSON.parse(
                JSON.stringify(getHeadScriptsConfig(state, requestLocale)),
              ),
              isJordan:
                selectors.brandNameSelector(state) === BRAND_NAMES.JORDAN,
              requestLocale,
              ...globalContentFeatures,
            },
          },
          optimizely: optimizelyData,
          navConfig: {
            pageDetail: selectors.pageDetailSelector(state),
            analyticsPageType: selectors.analyticsPageTypeSelector(state),
            globalNavPageName: selectors.globalNavPageNameSelector(state),
          },
        },
      };
    },
);

if (typeof window !== 'undefined') {
  // If no anonymousId cookie set from Akamai we need to set it by ouself.
  // Usually it is the case for preview environment.
  if (!getUserFromCookie()) {
    setAnonymousId();
  }
  // load the required polyfills
  loadPolyfills();
}

export const LandingPage = ({ optimizely }) => {
  const store = useStore();
  const metaTags = getMetadata(store.getState());
  const brandName = selectors.brandNameSelector(store.getState());

  return (
    <>
      <SEOMetaTags metaTags={metaTags} />
      <div
        id={ROOT_ELEMENT_ID}
        className={cx({
          [`${brandName} u-full-width u-full-height`]:
            brandName !== BRAND_NAMES.NIKE,
        })}
      >
        <Provider store={store}>
          <OptimizelyResolver
            datafile={optimizely?.datafile}
            forcedVariations={optimizely?.forcedVariations}
          >
            <RootContainer />
          </OptimizelyResolver>
        </Provider>
        <div id={NIKE_SHOP_ELEMENT_ID}></div>
      </div>
    </>
  );
};

LandingPage.propTypes = {
  optimizely: PropTypes.shape({
    children: PropTypes.node,
    datafile: PropTypes.object,
    forcedVariations: PropTypes.object,
    isDisabled: PropTypes.bool,
  }),
};

export default LandingPage;
