import React from 'react';
import PropTypes from 'prop-types';
import styles from '../dynamicCarousel.styl';
import SectionHeadline from '../../sectionHeadline';
import { sectionHeadlineShape } from '../../../shapes';

export const DynamicTwoInRow = ({
  sectionHeadline,
  headlineAnalyticsId,
  filmstripSlides,
  hasFeaturedCard,
}) => {
  return (
    <div className={styles.twoInRowWrapper}>
      {!hasFeaturedCard && (
        <SectionHeadline
          {...sectionHeadline}
          analyticsItemId={headlineAnalyticsId}
          className={styles.headlineText}
        />
      )}
      <div className={styles.twoInRow}>
        {filmstripSlides.map((slide, index) => (
          <div className={styles.twoInRowItem} key={index}>
            {slide}
          </div>
        ))}
      </div>
    </div>
  );
};

DynamicTwoInRow.propTypes = {
  sectionHeadline: sectionHeadlineShape,
  analyticsItemId: PropTypes.string,
  headlineAnalyticsId: PropTypes.string,
  filmstripSlides: PropTypes.arrayOf(PropTypes.node),
  hasFeaturedCard: PropTypes.bool,
};
