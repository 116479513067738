import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import styles from './metaBox.styl';
import { Price } from '../price';

const MetaBoxColumn = ({ title, children }) => (
  <div className={styles.column}>
    <div className={cx(styles.columnTitle, 'body-3')}>{title}</div>
    <div className={styles.columnContent}>{children}</div>
  </div>
);

MetaBoxColumn.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node || PropTypes.string,
};

const MetaBoxCard = ({ cardId, timeRequired, estimatedCost }) => {
  return (
    <div className={styles.metaBoxWrapper} id={cardId}>
      <MetaBoxColumn title={timeRequired?.title}>
        {timeRequired?.value}
      </MetaBoxColumn>
      {estimatedCost && (
        <MetaBoxColumn title={estimatedCost.title}>
          <Price listPrice={estimatedCost.value} />
        </MetaBoxColumn>
      )}
    </div>
  );
};

MetaBoxCard.propTypes = {
  cardId: PropTypes.string,
  timeRequired: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.any,
  }),
  estimatedCost: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.any,
  }),
};

export default MetaBoxCard;
