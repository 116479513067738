import React from 'react';
import cx from 'clsx';
import { BRAND_NAMES } from '@nike/ciclp-config';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import { smartTextCut } from '@nike/ciclp-redux-app/services/transforms/helpers';
import ImageMedia from '../imageMedia';
import { CardLink } from '../cardLink';
import styles from './contentSlide.styl';
import { gallerySlideShape } from '../../shapes';
import { BrandContext } from '../../context';
import { setCustomTextColor } from '../../helpers/setCustomTextColor';
import { VideoMedia } from '../videoMedia';

const MAX_BODY_LENGTH = 200;
const MAX_HEADLINE_LENGTH = 160;

const createMarkup = body => ({
  __html: body,
});

const getComponent = slide =>
  ({
    [CARD_TYPES.IMAGE]: (
      <ImageMedia
        {...slide}
        loadedImageClassNames={[styles.imageIsLoaded]}
        customClass={[styles.imageMedia]}
      />
    ),
    [CARD_TYPES.VIDEO]: (
      <VideoMedia
        {...slide}
        loop={false}
        autoPlay={false}
        showPlayButton
        alwaysMobile
        watchButtonText={slide.watchCtaButtonText}
        customClass={styles.imageMedia}
        timestampWithPlay
      />
    ),
  }[slide.containerType] ?? null);

const ContentSlide = ({ analyticsItemId, cardLinkMemberOnly, ...slide }) => (
  <figure
    data-analytics-placement-id={analyticsItemId}
    data-analytics-has-landmark="true"
    data-qa="content-slide"
    className={styles.slideContainer}
  >
    {getComponent(slide)}
    <CardLink
      actionId={slide.cardLinkId}
      memberOnly={cardLinkMemberOnly}
      destinationUrl={slide.cardLinkUrl}
      openInNewTab={slide.openInNewTab}
      renderAs="a"
      ariaLabel={[slide.subtitleProps.text, slide.titleProps.text].join(' ')}
      classNames={[styles.videoCardLink]}
    />
    <figcaption className={styles.slideTextWrapper}>
      {slide.subtitleProps.text && (
        <p
          className={cx([
            'body-3',
            'text-color-secondary',
            styles.slideSubTitle,
          ])}
          style={setCustomTextColor(slide.subtitleProps)}
          dangerouslySetInnerHTML={createMarkup(slide.subtitleProps.text)}
        />
      )}
      <BrandContext.Consumer>
        {brand => (
          <h4
            className={cx([
              brand === BRAND_NAMES.JORDAN ? 'headline-5' : 'body-1',
              'u-bold',
            ])}
            data-qa="title"
            role="heading"
            style={setCustomTextColor(slide.titleProps)}
            dangerouslySetInnerHTML={createMarkup(
              smartTextCut(slide.titleProps.text, MAX_HEADLINE_LENGTH),
            )}
          />
        )}
      </BrandContext.Consumer>
      {slide?.bodyProps?.text && (
        <p
          className={cx(['body-3', 'mt2-sm'])}
          style={setCustomTextColor(slide.bodyProps)}
          dangerouslySetInnerHTML={createMarkup(
            smartTextCut(slide.bodyProps.text, MAX_BODY_LENGTH),
          )}
        />
      )}
    </figcaption>
  </figure>
);

ContentSlide.propTypes = gallerySlideShape;

export default ContentSlide;
