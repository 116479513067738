import React from 'react';
import cx from 'clsx';
import { CAPTION_POSITIONS } from '@nike/ciclp-redux-app';
import { BRAND_NAMES } from '@nike/ciclp-config';
import CardOverlay from '../cardOverlay';
import { CardLink } from '../cardLink';
import { MultiCta } from '../ctaButton';
import { BodyText, CaptionText, SubtitleText, TitleText } from '../textOverlay';
import { isTextNotEmpty } from '../../helpers/mediaContent';

import styles from './filmstripSlide.styl';
import { gallerySlideShape } from '../../shapes';
import { BrandContext } from '../../context';
import { setCustomTextColor } from '../../helpers/setCustomTextColor';

const createMarkup = body => ({
  __html: body,
});

export const SlideContent = ({
  cardLinkMemberOnly,
  textLocation,
  analyticsItemId,
  cardLinkCollectionId,
  destinationType,
  filmstripId,
  cardLinkVariations,
  ...slide
}) => {
  let caption;
  const shouldRenderCaption =
    slide.actionButtons?.length > 0 ||
    slide.bodyProps.text ||
    slide.subtitleProps.text ||
    slide.titleProps.text;
  if (shouldRenderCaption) {
    const ctaButtons = (
      <MultiCta
        dataQa={slide.id}
        actionButtons={slide.actionButtons}
        isMenuOnTop={slide.isTextBelowImage}
        filmstripId={filmstripId}
        colorTheme={slide.colorTheme}
        addTopSpacing={isTextNotEmpty(
          slide.bodyProps.text,
          slide.subtitleProps.text,
          slide.titleProps.text,
        )}
      />
    );

    caption = slide.isTextBelowImage ? (
      <div className={styles.slideTextWrapper} data-qa="fig-caption">
        {slide.subtitleProps.text && (
          <p
            className={cx([
              'body-2',
              'text-color-secondary',
              'responsive-body-3-2',
              styles.slideSubTitle,
            ])}
            style={setCustomTextColor(slide.subtitleProps)}
            dangerouslySetInnerHTML={createMarkup(slide.subtitleProps.text)}
          />
        )}
        {slide.titleProps.text && (
          <BrandContext.Consumer>
            {brand => (
              <h4
                data-qa="title"
                className={cx([
                  brand === BRAND_NAMES.JORDAN ? 'headline-5' : 'headline-4',
                  styles.slideTitle,
                ])}
                style={setCustomTextColor(slide.titleProps)}
                dangerouslySetInnerHTML={createMarkup(slide.titleProps.text)}
              />
            )}
          </BrandContext.Consumer>
        )}
        {slide.bodyProps.text && (
          <p
            data-qa="bodyText"
            className={cx([
              'body-2',
              'text-color-primary-dark',
              styles.slideBodyText,
            ])}
            style={setCustomTextColor(slide.bodyProps)}
            dangerouslySetInnerHTML={createMarkup(slide.bodyProps.text)}
          />
        )}
        {ctaButtons}
      </div>
    ) : (
      <CardOverlay renderAs="div" position={textLocation}>
        <SubtitleText {...slide.subtitleProps} />
        <TitleText {...slide.titleProps} />
        <BodyText {...slide.bodyProps} />
        {ctaButtons}
      </CardOverlay>
    );
  }

  return (
    <>
      {slide?.captionProps?.position === CAPTION_POSITIONS.BELOW && (
        <CaptionText {...slide.captionProps} />
      )}
      {caption}
      {slide.cardLinkId && (
        <CardLink
          actionId={slide.cardLinkId}
          analyticsItemId={analyticsItemId}
          collectionId={cardLinkCollectionId}
          destinationType={destinationType}
          filmstripId={filmstripId}
          memberOnly={cardLinkMemberOnly}
          destinationUrl={slide.cardLinkUrl}
          openInNewTab={slide.openInNewTab}
          cardLinkVariations={cardLinkVariations}
          renderAs="a"
          ariaLabel={`${slide.subtitleProps?.text} ${slide.titleProps?.text}`}
          tabbable={!slide.actionButtons?.length}
        />
      )}
    </>
  );
};

SlideContent.propTypes = gallerySlideShape;
