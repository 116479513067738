import React from 'react';
import { ProductCard } from '@nike/nike-design-system-components';
import { useFavoritesPricing } from '@nike/podium-commons-business-logic';
import cx from 'clsx';
import { ImageMedia } from '../imageMedia';
import { enhancedProductCardShape } from '../../shapes';
import styles from './enhancedProductCard.styl';

const EnhancedProductCard = ({
  aspectRatio,
  detailsClass,
  url,
  imgUrl,
  altText,
  title,
  subtitle,
  formattedListPrice,
  formattedSalePrice,
  isOnSale,
  isMemberExclusive,
  isNikeByYou,
  overlayText,
  country,
  translations,
  textBadge,
  TextBadgeComponent,
}) => {
  const { addendum1, addendum2, price, discountPrice } = useFavoritesPricing({
    listPrice: formattedListPrice,
    locale: country?.toLowerCase(),
    discountPrice: formattedSalePrice,
    onSale: isOnSale,
    isTaxable: true,
  });

  return (
    <ProductCard
      imageAspectRatio={aspectRatio}
      components={{
        ImageComp: ImageMedia,
        // OverlayComp: null explicitly disables overlay
        OverlayComp: overlayText === undefined ? null : undefined,
      }}
      imageCompProps={{
        altText,
        assetsAspectRatios: {
          portrait: aspectRatio,
          landscape: aspectRatio,
        },
        customClass: cx(['product-slide-img', styles.productCardImg]),
        portraitURL: imgUrl,
        landscapeURL: imgUrl,
      }}
      classes={{
        details: detailsClass,
      }}
      products={[
        {
          hero: {
            image: {
              src: imgUrl,
              alt: altText,
            },
          },
          details: {
            title,
            category: subtitle,
            overlayText,
            textBadge: textBadge ?? Boolean(TextBadgeComponent),
            price,
            discountPrice,
            addendum1: translations[addendum1],
            addendum2: translations[addendum2],
            components: {
              TextBadgeComp: TextBadgeComponent,
              TitleComp: 'h4',
              CategoryComp: 'h5',
            },
            isNikeByYou,
            isMemberExclusive,
          },
          link: {
            href: url,
            title,
          },
        },
      ]}
    />
  );
};

EnhancedProductCard.defaultProps = {
  translations: {},
};

EnhancedProductCard.propTypes = {
  ...enhancedProductCardShape,
};

export default EnhancedProductCard;
