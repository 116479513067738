import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import ImageCard from '../imageCard';
import styles from './contentFeatured.styl';
import imageHeights from '../../constants/imageHeights.styl';
import { cardStyledTextShape, imageSizesShape } from '../../shapes';
import CardOverlay from '../cardOverlay';
import { useDimensionTypeContext } from '../../context';

const ContentFeatured = props => {
  const { isPortrait } = useDimensionTypeContext();
  const Title = props.withH1 ? 'h1' : 'h4';
  const SubTitleRenderer = props.withH1 ? 'h2' : 'h5';
  const cardOverlay = (
    <CardOverlay
      renderAs="figcaption"
      position={{ horizontal: 'center', vertical: 'after' }}
      overlayClassName={styles.overlay}
    >
      {props.titleProps.text !== '' && (
        <Title
          data-qa="title"
          className="u-text-transform-unset u-futura-line-height u-futura-font-size marketing-font"
        >
          {props.titleProps.text}
        </Title>
      )}
      {props.subtitleProps.text !== '' && (
        <SubTitleRenderer
          data-qa="subtitle"
          className="body-3 text-color-secondary"
        >
          {props.subtitleProps.text}
        </SubTitleRenderer>
      )}
      {props.bodyProps.text !== '' && (
        <p data-qa="body" className={isPortrait ? 'body-3' : 'body-1'}>
          {props.bodyProps.text}
        </p>
      )}
    </CardOverlay>
  );

  return (
    <div
      className={styles.featuredWrapper}
      data-qa-content="editorial-featured-content"
    >
      <ImageCard
        {...props}
        customClass={cx(imageHeights.maintain, styles.imageMedia)}
        textLocation={{
          horizontal: 'center',
          vertical: 'after',
        }}
        cardOverlayElement={cardOverlay}
      />
    </div>
  );
};

ContentFeatured.propTypes = {
  withH1: PropTypes.bool,
  bodyProps: cardStyledTextShape,
  subtitleProps: cardStyledTextShape,
  titleProps: cardStyledTextShape,
  imageHeight: imageSizesShape,
};

export default ContentFeatured;
