import React from 'react';
import PropTypes from 'prop-types';
import { TextStyled } from '@nike/nike-design-system-components';
import { PriceWrapper } from './price.styles';

export const Price = ({ listPrice, salePrice, isOnSale }) => {
  return (
    <PriceWrapper data-qa="price-wrapper" className="price-wrapper">
      <TextStyled
        Component="span"
        className={isOnSale ? 'original-price' : 'price'}
        display="inline"
        appearance="body2"
        bold
        color={isOnSale ? 'secondary' : 'primary'}
        strikethrough={isOnSale}
      >
        {listPrice}
      </TextStyled>
      {isOnSale && (
        <TextStyled
          Component="span"
          className="sale-price"
          bold
          display="inline"
          appearance="body2"
        >
          {salePrice}
        </TextStyled>
      )}
    </PriceWrapper>
  );
};

Price.propTypes = {
  listPrice: PropTypes.string,
  salePrice: PropTypes.string,
  isOnSale: PropTypes.bool,
};
