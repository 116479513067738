import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useThrottle } from '@react-hook/throttle';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  useBreakpoint,
  useMatchMedia,
} from '@nike/nike-design-system-components';
import { useWindowEvent } from './hooks';
import { LocalNavDropdownStyled } from './LocalNavDropdownStyled';
import { LocalNavMenuStyled } from './LocalNavMenuStyled';

export const LocalNav = props => {
  const {
    TitleComponent,
    LinkComponent,
    items,
    titleProps,
    className,
    withSticky,
    showOnlySticky,
    switchBreakpoint,
    containerClassName,
    alignment,
    stickyParam,
  } = props;

  const ref = useRef(null);

  const [isSticky, setSticky] = useThrottle(false);
  const [placeholderHeight, setPlaceholderHeight] = useThrottle('auto');
  const clientMenuMatch = useMatchMedia(useBreakpoint('lte', switchBreakpoint));
  const [showAsDropdown, setShowAsDropdown] = useState(true);
  const updatePlaceholderHeight = useCallback(() => {
    if (!showOnlySticky)
      setPlaceholderHeight(
        `${
          ref.current
            .querySelector('.sticky-placeholder')
            ?.getBoundingClientRect().height
        }px`,
      );
  }, [setPlaceholderHeight, showOnlySticky]);
  const handleScroll = () => {
    if (
      withSticky &&
      ref.current.getBoundingClientRect()[stickyParam] < 0 !== isSticky
    ) {
      setSticky(ref.current.getBoundingClientRect()[stickyParam] < 0);
      updatePlaceholderHeight();
    }
  };

  useWindowEvent('scroll', handleScroll);
  useWindowEvent('resize', updatePlaceholderHeight);

  useEffect(() => {
    setShowAsDropdown(clientMenuMatch);
    setSticky(
      withSticky && ref.current.getBoundingClientRect()[stickyParam] < 0,
    );
    updatePlaceholderHeight();
  }, [
    setShowAsDropdown,
    clientMenuMatch,
    setSticky,
    withSticky,
    ref,
    stickyParam,
    updatePlaceholderHeight,
  ]);

  const localNavInner = showAsDropdown ? (
    <LocalNavDropdownStyled
      TitleComponent={TitleComponent}
      LinkComponent={LinkComponent}
      items={items}
      titleProps={titleProps}
      isSticky={isSticky}
      customClassName={clsx([
        'sticky-container',
        containerClassName,
        isSticky && 'is-sticky',
        showOnlySticky && !isSticky && 'sticky-container-hidden',
        (showOnlySticky || stickyParam === 'bottom') &&
          'sticky-container-animation',
      ])}
      placeHolderClass="sticky-placeholder"
      alignment={alignment}
    />
  ) : (
    <LocalNavMenuStyled
      items={items}
      titleProps={titleProps}
      LinkComponent={LinkComponent}
      TitleComponent={TitleComponent}
      containerClassName={containerClassName}
      customClassName={clsx([
        'sticky-container',
        containerClassName,
        isSticky && 'is-sticky',
        showOnlySticky && !isSticky && 'sticky-container-hidden',
        (showOnlySticky || stickyParam === 'bottom') &&
          'sticky-container-animation',
      ])}
      placeHolderClass="sticky-placeholder"
    />
  );

  return (
    <div
      className={className}
      ref={ref}
      style={{ minHeight: placeholderHeight }}
    >
      {localNavInner}
    </div>
  );
};

LocalNav.propTypes = {
  /** Component to render title in local nav */
  TitleComponent: PropTypes.elementType,
  /** Component to render links in menu  */
  LinkComponent: PropTypes.elementType,
  /** Class name(s) to apply to the root element. (passed as a part of styled component) */
  className: PropTypes.string,
  /** Container class name(s) to apply to the root element that is passed as props. */
  containerClassName: PropTypes.string,
  /** Props that are passed to title component */
  titleProps: PropTypes.shape({
    text: PropTypes.string,
  }),
  /** Props that are passed to title component */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ),
  /** Flag that should be passed in case you want sticky behaviour */
  withSticky: PropTypes.bool,
  /** Breakpoint that shows when to make a switch between navigation representation */
  switchBreakpoint: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  /** Indicator for sticky detection (top, bottom) */
  stickyParam: PropTypes.string,
  /** Flag to show local nav only for when sticky (adds also animation) */
  showOnlySticky: PropTypes.bool,
  /** Alignment of items (for dropdown) */
  alignment: PropTypes.string,
};

LocalNav.defaultProps = {
  withSticky: false,
  switchBreakpoint: 'm',
  stickyParam: 'top',
};
