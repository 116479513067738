import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import NikeOneVideoPlayer, { Preset } from '@nike/nike-one-video-player';

import { Preview } from './plugins/preview-plugin';
import { PauseOnLeave } from './plugins/pause-on-leave';
import { ReactAdapter } from './plugins/react-adapter';
import { CurrentTimeSetter } from './plugins/current-time-setter';

import { PlayerOverlay } from './components/playerOverlay';
import { useDimensionTypeContext } from '../../context';

import styles from './videoMedia.styl';

import { VIDEO_EVENTS } from './constants';
import { reducer, VIDEO_PLAYER_STATE } from './localReducer';
import { useIsVideoRendered, useResolvedVideoParams } from './hooks';
import { shouldShowOverlay, shouldShowPlayButton } from './helpers';
import { REACT_ADAPTER_CALLBACKS } from './pluginMappings';
import { AnalyticsHandler } from './plugins/analytics-handler';
import { AspectRatioWrapper } from '../aspectRatioWrapper';

if (NikeOneVideoPlayer) {
  NikeOneVideoPlayer.registerPlugin('preview', Preview);
  NikeOneVideoPlayer.registerPlugin('pauseOnLeave', PauseOnLeave);
  NikeOneVideoPlayer.registerPlugin('reactAdapter', ReactAdapter);
  NikeOneVideoPlayer.registerPlugin('currentTimeSetter', CurrentTimeSetter);
  NikeOneVideoPlayer.registerPlugin('analyticsHandler', AnalyticsHandler);
}

const VideoMedia = ({
  videoId,
  autoPlay: autoplay,
  portraitVideoId,
  loop,
  controlOptions,
  locale,
  isCardOverlayExist,
  isTextPositionAfter,
  children,
  portraitPosterUrl,
  landscapePosterUrl,
  timestampWithPlay,
  previewOnHover,
  watchCtaButtonText,
  forceFullscreenOnStart,
  customAttrs,
  customClass,
  watchCtaButtonStyle,
  assetsAspectRatios,
  pauseOnLeave,
}) => {
  const [videoState, dispatchFn] = useReducer(reducer, VIDEO_PLAYER_STATE);

  const { isPortrait, isMobile, isInitedOnClient, orientation } =
    useDimensionTypeContext();

  const processedIsMobile = forceFullscreenOnStart || isMobile;

  const [wrapper, isVideoRendered] = useIsVideoRendered({
    isMobile: processedIsMobile,
    isInitedOnClient,
  });

  const [posterImage, renderedVideoId] = useResolvedVideoParams({
    isPortrait,
    portraitPosterUrl,
    landscapePosterUrl,
    portraitVideoId,
    videoId,
  });

  return (
    <div className={styles.videoContainer} {...customAttrs}>
      <AspectRatioWrapper
        ref={wrapper}
        aspectRatios={assetsAspectRatios}
        orientation={orientation}
        data-video-id={renderedVideoId}
        data-autoplay-video={autoplay}
        data-preview-started={videoState.previewStarted}
        className={cx('media-container', customClass, styles.videoPlaceholder)}
      >
        {isVideoRendered && (
          <NikeOneVideoPlayer
            videoId={renderedVideoId}
            preset={Preset.fullControls}
            locale={locale}
            poster={posterImage}
            playerOptions={{
              autoplay,
              loop,
              aspectRatio: '16:9',
              loadingSpinner: false,
              bigPlayButton: false,
              muted: true,
              customProperties: {
                forceFullscreenOnStart,
                isCardOverlayExist,
                controlOptionsFromCMS: controlOptions === 'full',
              },
              plugins: {
                currentTimeSetter: {
                  enable: portraitVideoId !== videoId && isMobile,
                },
                analyticsHandler: true,
                preview: {
                  disableClass: styles.disableUserSelect,
                  previewWrapperSelector: '[data-preview-wrapper]',
                  enable: previewOnHover,
                },
                pauseOnLeave: {
                  enabled: pauseOnLeave,
                  threshold: 0.1,
                },
                reactAdapter: {
                  eventsToObserve: [
                    VIDEO_EVENTS.PLAY,
                    VIDEO_EVENTS.PAUSE,
                    VIDEO_EVENTS.ENDED,
                    VIDEO_EVENTS.FULLSCREENCHANGE,
                    VIDEO_EVENTS.LOADEDMETADATA,
                    VIDEO_EVENTS.STATECHANGED,
                  ],
                  dispatchFn,
                  callbacks: REACT_ADAPTER_CALLBACKS,
                },
              },
            }}
          />
        )}
      </AspectRatioWrapper>
      <PlayerOverlay
        watchCtaButtonStyle={watchCtaButtonStyle}
        imageHeightClass={customClass}
        duration={videoState.duration?.toString()}
        showTimestamp={Boolean(videoState.duration && !autoplay)}
        timestampWithPlay={timestampWithPlay}
        isTextPositionAfter={isTextPositionAfter}
        showPlayButton={shouldShowPlayButton(videoState, {
          autoplay,
        })}
        forceFullscreenOnStart={forceFullscreenOnStart}
        watchCtaButtonText={watchCtaButtonText}
        showPlayerOverlay={shouldShowOverlay({
          videoState,
          isCardOverlayExist,
        })}
      >
        {children}
      </PlayerOverlay>
    </div>
  );
};

VideoMedia.propTypes = {
  videoId: PropTypes.string.isRequired,
  portraitVideoId: PropTypes.string,
  previewOnHover: PropTypes.bool,
  forceFullscreenOnStart: PropTypes.bool,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  controlOptions: PropTypes.string,
  locale: PropTypes.string,
  isCardOverlayExist: PropTypes.bool,
  isTextPositionAfter: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  portraitPosterUrl: PropTypes.string,
  landscapePosterUrl: PropTypes.string,
  timestampWithPlay: PropTypes.bool,
  pauseOnLeave: PropTypes.bool,
  watchCtaButtonText: PropTypes.string,
  watchCtaButtonStyle: PropTypes.string,
  customAttrs: PropTypes.object,
  customClass: PropTypes.string,
  assetsAspectRatios: PropTypes.shape({
    landscape: PropTypes.number,
    portrait: PropTypes.number,
  }),
};

export default React.memo(VideoMedia);
