import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { SizeChartStyled } from '@nike/nike-design-system-components';
import { selectors } from '@nike/ciclp-redux-app';
import styles from './sizeChartsWrapper.styl';
import { UnitsToggleContainer } from '../unitsToggleContainer';

export const SizeChartsWrapper = props => {
  const savedMeasurementsSettings = useSelector(
    selectors.measurementSelector,
    shallowEqual,
  );

  const getCaption = (caption, measurements) =>
    measurements ? (
      <div className={styles.sizeChartsTitle}>
        {caption}
        <UnitsToggleContainer
          unitsType={measurements.systemToggle}
          values={measurements.values}
        />
      </div>
    ) : (
      caption
    );

  const getFilteredRowsByMeasurementSystem = (data, system) =>
    data.filter(
      row =>
        row.filter(
          col =>
            !col.system || col.system === savedMeasurementsSettings[system],
        ).length > 0,
    );

  return props.sizeCharts?.length ? (
    <div
      className="fixed-fluid"
      data-analytics-placement-id={props.analyticsItemId}
    >
      {props?.sizeCharts?.map(({ id, data = [[]], measurements }) => (
        <div key={id} className={styles.sizeChartContainer}>
          <SizeChartStyled
            className={styles.sizeChartsWrapper}
            caption={getCaption(props.title, measurements)}
            data={getFilteredRowsByMeasurementSystem(
              data,
              measurements.systemToggle,
            )}
          />
        </div>
      ))}
    </div>
  ) : null;
};

SizeChartsWrapper.defaultProps = {
  sizeCharts: [],
};

SizeChartsWrapper.propTypes = {
  title: PropTypes.string,
  analyticsItemId: PropTypes.string,
  sizeCharts: PropTypes.arrayOf({
    // measurements:
    id: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    ),
  }),
};
