import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const SEOMetaTags = ({ metaTags }) => (
  <Head>
    {
      // meta, link and title need to be a direct child for next/head element
    }
    {metaTags?.meta?.length &&
      metaTags.meta.map((metaTag, index) => (
        <meta {...metaTag} key={`meta-tag-${index}`} />
      ))}
    {metaTags?.link?.length &&
      metaTags.link.map((linkTag, index) => (
        <link {...linkTag} key={`link-tag-${index}`} />
      ))}
    <title>
      {typeof metaTags?.titleTemplate === 'function'
        ? metaTags.titleTemplate(metaTags?.title || '')
        : metaTags?.title || ''}
    </title>
  </Head>
);

SEOMetaTags.propTypes = {
  metaTags: PropTypes.object,
};

export default SEOMetaTags;
