import styled from '@emotion/styled';
import { IMAGE_SIZES_MAP } from '@nike/ciclp-redux-app';

const getInitialAspectRatio = ({ portrait, landscape }, isPortrait) =>
  isPortrait ? portrait : landscape;

const getTopPadding =
  ({ isPortrait = false } = {}) =>
  ({ height, isLoaded, aspectRatios }) => {
    // pow aspect ratio by -1 and then multiply by 100%. Needs to transform it from w/h format to h/w
    const invertedAspectRatio =
      getInitialAspectRatio(aspectRatios, isPortrait) ** -1 * 100;

    const cssValue = `${invertedAspectRatio}%`;

    return (
      !isLoaded && (height === IMAGE_SIZES_MAP.MAINTAIN || !height) && cssValue
    );
  };

const ImageWrapper = styled.div`
  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    padding-top: ${getTopPadding()};
    @media only screen and (max-width: 640px) and (orientation: portrait) {
      padding-top: ${getTopPadding({ isPortrait: true })};
    }
  }
  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

ImageWrapper.displayName = 'ImageWrapper';

export default ImageWrapper;
