import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { BRAND_NAMES } from '@nike/ciclp-config';
import debounce from 'debounce';
import { NikeDesignSystemProvider } from '@nike/nike-design-system-components';
import {
  getDimensionType,
  isPortraitView,
  MOBILE,
  TABLET,
  DESKTOP,
  DESKTOP_WIDE,
  DESKTOP_LARGE,
} from './utils';

export const BrandContext = React.createContext(BRAND_NAMES.NIKE);
export const defaultDimensionContextValue = {
  dimensionType: MOBILE,
  isMobile: true,
  isTablet: false,
  isDesktop: false,
  isDesktopLarge: false,
  isDesktopWide: false,
  isPortrait: false,
  isInitedOnClient: false,
};
export const DimensionTypeContext = React.createContext(
  defaultDimensionContextValue,
);

export const useDimensionTypeContext = () => useContext(DimensionTypeContext);

const useDimensionType = () => {
  const [dimensionType, setDimensionType] = useState(MOBILE);
  const [isPortrait, setIsPortrait] = useState(true);
  const [isInitedOnClient, setIsInitedOnClient] = useState(false);

  useEffect(() => {
    const updateStates = () => {
      setDimensionType(getDimensionType());
      setIsPortrait(isPortraitView());
      setIsInitedOnClient(true);
    };
    const debouncedUpdateDimensionType = debounce(updateStates, 100);

    updateStates();
    window.addEventListener('resize', debouncedUpdateDimensionType);

    return () => {
      window.removeEventListener('resize', debouncedUpdateDimensionType);
    };
  }, []);

  return {
    dimensionType,
    isMobile: dimensionType === MOBILE,
    isTablet: dimensionType === TABLET,
    isDesktop: dimensionType === DESKTOP,
    isDesktopLarge: dimensionType === DESKTOP_LARGE,
    isDesktopWide: dimensionType === DESKTOP_WIDE,
    isPortrait,
    isInitedOnClient,
    orientation: isPortrait ? 'portrait' : 'landscape',
  };
};

export const AppContextProvider = props => {
  const dimensionType = useDimensionType();

  return (
    <DimensionTypeContext.Provider value={dimensionType}>
      <BrandContext.Provider value={props.brand}>
        <NikeDesignSystemProvider>{props.children}</NikeDesignSystemProvider>
      </BrandContext.Provider>
    </DimensionTypeContext.Provider>
  );
};

AppContextProvider.propTypes = {
  brand: PropTypes.string,
  children: PropTypes.any,
};
