import NikeOneVideoPlayer from '@nike/nike-one-video-player';
import { VIDEO_EVENTS, VIDEO_DURATION_TOLERANCE } from '../../constants';

const BasePlugin = NikeOneVideoPlayer.getPlugin('plugin');

export class CurrentTimeSetter extends BasePlugin {
  constructor(player, options) {
    super(player, options);
    this.options = options;
    if (this.options.enable) {
      this.addListeners();
    }
  }

  isTheSameVideoDuration() {
    return (
      Math.abs(this.state.duration - this.player.duration()) <=
      VIDEO_DURATION_TOLERANCE
    );
  }

  isTheSameVideoSrc() {
    const safelyExposedTech = this.player.tech(true);
    return safelyExposedTech.currentSrc() === this.state.src;
  }

  handlePlayerResize = () => {
    if (this.player?.isFullscreen()) {
      this.setState({
        timestamp: this.player.currentTime(),
        duration: this.player.duration(),
      });
    }
  };

  handlePlayerCanPlay = () => {
    const isTimestampSaved = this.state.timestamp;
    const safelyExposedTech = this.player.tech(true);

    if (isTimestampSaved) {
      if (this.isTheSameVideoDuration() && !this.isTheSameVideoSrc()) {
        this.player.currentTime(this.state.timestamp);
      }
      this.player.muted(false);
      this.player.play();
      this.setState({
        src: safelyExposedTech.currentSrc(),
        currentTime: null,
        duration: null,
      });
      return;
    }

    this.setState({
      src: safelyExposedTech.currentSrc(),
    });
  };

  addListeners() {
    this.player.on(VIDEO_EVENTS.PLAYERRESIZE, this.handlePlayerResize);
    this.player.on(VIDEO_EVENTS.CANPLAY, this.handlePlayerCanPlay);
    this.player.on(VIDEO_EVENTS.DISPOSE, this.removeListeners);
  }

  removeListeners = () => {
    this.player?.off(VIDEO_EVENTS.PLAYERRESIZE, this.handlePlayerResize);
    this.player?.off(VIDEO_EVENTS.CANPLAY, this.handlePlayerCanPlay);
  };
}
