import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './cardOverlay.styl';
import { cardOverlayPositionsShape } from '../../shapes';

const css = ({ horizontal, vertical }) => {
  const horizontalFix = horizontal === 'before' ? 'start' : horizontal;
  return cx([styles[`h_${horizontalFix}`], styles[`v_${vertical}`]]);
};

const CardOverlay = ({ children, position, renderAs, overlayClassName }) => {
  const Renderer = renderAs; // needs to be Uppercase, otherwise React will render as `<varname/>`
  return (
    <Renderer
      className={cx([styles.overlay, css(position)])}
      data-qa="card-overlay"
    >
      <div
        className={cx(styles.content, overlayClassName)}
        data-qa="card-overlay-content"
      >
        {children}
      </div>
    </Renderer>
  );
};

CardOverlay.displayName = 'CardOverlay';

CardOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  position: cardOverlayPositionsShape,
  renderAs: PropTypes.oneOf(['div', 'figcaption']),
  overlayClassName: PropTypes.string,
};

CardOverlay.defaultProps = {
  position: {
    horizontal: 'start',
    vertical: 'start',
  },
  renderAs: 'div',
};

export default CardOverlay;
