export const getSlideContentProps = ({
  id,
  cardLinkMemberOnly,
  cardLinkUrl,
  cardLinkId,
  colorTheme,
  openInNewTab,
  subtitleProps,
  titleProps,
  bodyProps,
  textLocation,
  isTextBelowImage,
  destinationType,
  sectionHeadlineTitle,
  filmstripId,
  analyticsItemId,
  cardLinkCollectionId,
}) => ({
  id,
  cardLinkMemberOnly,
  cardLinkUrl,
  cardLinkId,
  colorTheme,
  openInNewTab,
  subtitleProps,
  titleProps,
  bodyProps,
  textLocation,
  isTextBelowImage,
  destinationType,
  sectionHeadlineTitle,
  filmstripId,
  analyticsItemId,
  cardLinkCollectionId,
});
